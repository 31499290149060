import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext, UserStorageContext, BalanceStorageContext, NotificationContext } from '../../../contex';
import { fetchEntry, fetchSeed, sendTransfer, sendSwap } from "../../../http/api_wallets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import {TokenToGwei,  walletShortener, gweiToEth, sunToTrx, isValidTronAddress } from "../../../utils/utils";
import ModalCompleteWithdraw from "./ModalCompleteWithdraw";

import { Buffer } from 'buffer';
import { ABI_TRC20, ABI_ROUTER_V2 } from '../wallet/abi'


const WithdrawPage = () => {
const navigate = useNavigate();  
const {userStorage, setUserStorage} = useContext(UserStorageContext) // Хранилище данных
const {balanceStorage, setBalanceStorage}  = useContext(BalanceStorageContext) // Хранилище данных
const { Notification }  = useContext(NotificationContext) // Всплывающие сообщения
const [show_SelectToken, set_show_SelectToken] = useState(false)


const elementRef = useRef(null);

   useEffect(() => {
       // Функция для обработки кликов по документу
       const handleClickOutside = (event) => {
           // Проверяем, что целевой элемент существует и клик был вне этого элемента
           if (elementRef.current && !elementRef.current.contains(event.target)) {
            //console.log('Клик произошел вне компонента!');
           // console.log('elementRef', elementRef);
           set_show_SelectToken(false)
           }
       };

       // Добавляем обработчик события `click` на весь документ
       document.addEventListener('click', handleClickOutside);

       // Удаляем обработчик при размонтировании компонента
       return () => {
           document.removeEventListener('click', handleClickOutside);
       };
   }, []);

const goToBackPage = (withdrawType) => {
  navigate(ROUTES.WALLET);  // Перенаправляем на другую страницу
};

//const [selectedToken, set_selectedToken] = useState(userStorage.withdrawType)
const [show_Balance, set_show_Balance] = useState(userStorage.withdrawType == "ww3"? 
   gweiToEth(balanceStorage.ww3_balance) : sunToTrx(balanceStorage.trx_balance))


const openSelectToken = () =>{
   set_show_SelectToken(!show_SelectToken)
}

const selectToken = (tokenName) =>{
   if (tokenName == "ww3") {
      set_show_Balance(gweiToEth(balanceStorage.ww3_balance))
   } else  if (tokenName == "trx"){
      set_show_Balance(sunToTrx(balanceStorage.trx_balance))
   }   
   setUserStorage(prevState => ({
      ...prevState,
      withdrawType: tokenName, // Обновляем userData
    }));
   set_show_SelectToken(!show_SelectToken)
}


// Работа с полям ввода числа 
const [reciver, set_Reciver] = useState(null);
const [inputToken, setInputToken] = useState(0) // Токены в поле ввода You Send
const [paymentTokenAmount, set_paymentTokenAmount] = useState('0')
let DECIMALS = { // Объект для хранения DECIMALS токенов
   trx:  6,  ww3:  18, 
 }
useEffect(()=>{ // Работаем с числом из поля ввода You Send
   if (inputToken<0) { // защищаем от отрицательных значений
      setInputToken(0)
   }
   // при изменении количества отдаваемых конвертируем число для работы с блокчейном
   let newAmountToken = (inputToken * 10 ** DECIMALS[userStorage.withdrawType]).toFixed(0);
   newAmountToken = Number(newAmountToken).toLocaleString('fullwide', {useGrouping:false})
   set_paymentTokenAmount(newAmountToken)
   //console.log("newAmountToken", newAmountToken)  
 },[inputToken, userStorage.withdrawType])

// Работа с полем ввода кошелька

// Работа с кнопкой 
const actionText =  () => {
   const userBalanceWei = userStorage.withdrawType == "ww3"? 
   balanceStorage.ww3_balance : balanceStorage.trx_balance // баланс юзера  в текущем токене в веях

   const balanceForFee = 3 * 10 ** 6 // количество которое нужно на балансе для оплаты коммисий
  
   if (Number(paymentTokenAmount) == 0 ) { // если в поле ввода введен 0
      return "ENTER VALUE"
   } 
   if (userStorage.withdrawType == "trx") { // Проверяем хватит ли на оплату коммисий
      if (Number(balanceStorage.trx_balance) <= Number(balanceForFee) + Number(paymentTokenAmount) ) {
          return "NOT ENOUGH TRX FOR GAS"
      }      
   } else if (userStorage.withdrawType == "ww3") {
      if (Number(balanceStorage.trx_balance) <= Number(balanceForFee) ) {
         return "NOT ENOUGH TRX FOR GAS"
     }  
   }
   if (Number(paymentTokenAmount)<=Number(userBalanceWei)) { // проверяем достаточно ли средств для трасфера
     
   } else {
      return `NOT ENOUGH ${userStorage.withdrawType.toUpperCase()}`
   }
   if (!isValidTronAddress(reciver)) {// проверяем валидность адреса получателя
      return "ENTER VALID RECIPIENT"
   } 
    return "TRANSFER"
}
// Функция отправки трансфера на бэк
const processTransfer = async () => {
   try {      
      Notification("Transaction sending...", "success");
      const result = await sendTransfer({withdrawType: userStorage.withdrawType, paymentTokenAmount, reciver });
      console.log(result)
      if (result.status =="OK") {
         Notification(result.message, "success")
      } else if (result.status =="ERROR") {
         Notification(result.message, "error")
      }     
   } catch (error) {
      Notification('client error', "error")
      console.log(error)
   }   
}
// Функция обработки нажатия кнопки, чтобы не дублировать логику берем за основу логику текстовых надписей
const [show_ModalCompleteTransfer, set_show_ModalCompleteTransfer] = useState(false);
const handleButtonClick = () => {
   const action = actionText();
   switch (action) {
       case "ENTER VALUE":
         console.log("Please enter a value to transfer.");
         Notification("Please enter a value to transfer.", "error");
           break;
       case "ENTER VALID RECIPIENT":
         console.log("Please enter a valid recipient address.");
         Notification("Please enter a valid recipient address.", "error");
           break;
       case "NOT ENOUGH TRX FOR GAS":
         console.log("You do not have enough TRX to cover the gas fees.");
         Notification("You do not have enough TRX to cover the gas fees.", "error");
           break;
       case "TRANSFER":
           // Открываем модальное окно в котором по клику запускаем процесс трансфера         
           set_show_ModalCompleteTransfer(true)
           break;
       default:
           Notification(action, "error");
           console.log(action); // Показываем сообщение в случае нехватки средств
           break;
   }
};


/////////////////////////// Обновление балансов /////////////////////////// 

window.Buffer = Buffer;
const TronWeb = window.TronWeb // TronWeb подключается как js в index.html
const tronWeb = new TronWeb({
    fullHost: process.env.REACT_APP_TRON_FULL_HOST,
    privateKey: '54F7334A1C5D1DA669119452D56F49141C95F5EF692FB83851B6DCC32A4C7D5A' // моковый ключ иначе эта версия библиотеки глючит на фронте
});

const wtrx_TRC20_Address = process.env.REACT_APP_ADDRESS_WTRX 
const ww3_TRC20_Address  = process.env.REACT_APP_ADDRESS_WW3
const usdt_TRC20_Address  = process.env.REACT_APP_ADDRESS_USDT
const routerAddress      = process.env.REACT_APP_ADDRESS_SUNSWAP_ROUTER_V2; // роутер сансвапа

const requests = async () => {//Функция отвечающая за переодические запросы к блокчейну
try {   console.log(userStorage)
    // Получаем баланс TRX
    const trx_balance_in_sun = await tronWeb.trx.getBalance(userStorage.userData.account);      
    console.log('trx_balance_in_sun', String(trx_balance_in_sun)) 
    // Получаем баланс WW3
    const WW3Contract = await tronWeb.contract(ABI_TRC20, ww3_TRC20_Address); 
    // Вызов метода balanceOf контракта токена
    const ww3_balance_in_gwei = await WW3Contract.methods.balanceOf(userStorage.userData.account).call();
    console.log('ww3_balance_in_gwei', String(ww3_balance_in_gwei)) 
    // Получаем курс TRX
    const routerContract = await tronWeb.contract().at(routerAddress);

    console.log(wtrx_TRC20_Address, usdt_TRC20_Address) 
   
    const TRXUSDT_amountsOut = await routerContract.methods.getAmountsOut(tronWeb.toSun('1'),
    [wtrx_TRC20_Address, usdt_TRC20_Address]).call();
    const TRXUSDT_resultAmounts = String(TRXUSDT_amountsOut[0])
    const TRXUSDT_resultAmountsArr = TRXUSDT_resultAmounts.split(',')

    console.log('TRXUSDT_resultAmountsArr', TRXUSDT_resultAmountsArr) 
    let trxInUSDTCourse = (TRXUSDT_resultAmountsArr[1] /( 10 ** 6))//.toFixed(6);
    //trxInUSDTCourse = Number(trxInUSDTCourse).toLocaleString('fullwide', {useGrouping:false})   
    console.log('trxInUSDTCourse', trxInUSDTCourse) 

    // Получаем курс WW3 к трону а затем пересчитываем по курсу
    // узнаем сколько получим за 1м токенов
    const WW3USDT_amountsOut = await routerContract.methods.getAmountsOut(TokenToGwei(1000000, 18),
    [ww3_TRC20_Address, wtrx_TRC20_Address]).call();
    const WW3USDT_resultAmounts = String(WW3USDT_amountsOut[0])
    const WW3USDT_resultAmountsArr = WW3USDT_resultAmounts.split(',')

    //console.log('WW3USDT_resultAmountsArr', WW3USDT_resultAmountsArr) 
    // ранее мы узнали сколько трона в SUNах получим за 1000_000 токенов ww3
    // конвертируем в целые единицы TRX
    let ww3InUSDTCourse = (WW3USDT_resultAmountsArr[1] /( 10 ** 6));
    //console.log('ww3InUSDTCourse', ww3InUSDTCourse)
    // теперь умножаем количество TRX на курс к USDT и получаем сумму в баксах за 1м
     ww3InUSDTCourse = Number( ww3InUSDTCourse) * Number(trxInUSDTCourse) 
    //ww3InUSDTCourse = Number(ww3InUSDTCourse).toLocaleString('fullwide', {useGrouping:false})   
    
    ww3InUSDTCourse = parseFloat(ww3InUSDTCourse.toFixed(6))
    console.log('ww3InUSDTCourse', ww3InUSDTCourse)
    setBalanceStorage(prevState => ({
        ...prevState,
        trxInUSDTCourse: trxInUSDTCourse,
        ww3InUSDTCourse: ww3InUSDTCourse, // не забываем что это курс за 1м
        trx_balance: trx_balance_in_sun,
        ww3_balance: String(ww3_balance_in_gwei), // Обновляем userData
      }));
    // Получаем курс WW3  
} catch (error) {
  console.log(error)
}
}

useEffect(()=>{ // Запускаем переодическое получение информации из блокчейна
const interval = setInterval(async ()=>{
  console.log("requests()")
  try {
      requests();    
  } catch (error) {
    console.log(error)
  }
}, process.env.REACT_APP_DEX_REQUEST_INTERVAL)
return() => {
    clearInterval(interval);
};
},[userStorage.userData.account])

const handleSourceAmountChange = (e) => {
   let inputValue = e.target.value;

   // Убираем ведущий ноль, если он есть, и значение больше одной цифры
   if (inputValue.length > 1 && inputValue[0] === '0') {
       inputValue = inputValue.slice(1);
   }

   // Проверка, что значение содержит только цифры
   if (/^\d*$/.test(inputValue)) {
      setInputToken(inputValue);
   }

};

    return (
<>
{show_ModalCompleteTransfer &&
<ModalCompleteWithdraw  
    YouSend = { inputToken }
    targetToken = { userStorage.withdrawType.toUpperCase() }
    TxFee = { 1 }
    set_show_Modal = { set_show_ModalCompleteTransfer }
    confirm_swap = { processTransfer }
    thisWallet = { reciver }
    reciver  = { reciver }
/>}
<main className="main">
   <section className="withdraw">
      <div className="container">
         <div className="withdraw__controls">
            <Link className="withdraw__controls-back" to={ROUTES.WALLET}></Link>
            
            <div className="custom-select__selected" style={{width: 'fit-content'}}>
               <span className="custom-select__selected-text">TRON NETWORK</span>
            </div>
         </div>
         <div className="withdraw__swap">
            <div className="withdraw__swap-heading">
               <span className="withdraw__swap-heading-title">Withdraw</span>
               <span className="withdraw__swap-heading-limit">Max: {show_Balance} {userStorage.withdrawType.toUpperCase()}</span>
            </div>
            <form className="withdraw__swap-form" onClick={event => event.preventDefault()}>
               <fieldset className="withdraw__swap-form-fieldset">
                  <input
                     className="withdraw__swap-form-input"
                     type="number"
                    
                     value={inputToken}
                     onChange={event=>handleSourceAmountChange(event)}
                     />
                  <div className="custom-select" data-value={userStorage.withdrawType}  ref={elementRef}>             
                     <div className="custom-select__selected"
                     onClick={event=> openSelectToken()}
                     >
                        <span className="custom-select__selected-text">{userStorage.withdrawType.toUpperCase()}</span>
                     </div>
                     {show_SelectToken && <div className="custom-select__items ">
                        <div 
                        className={["custom-select__item", userStorage.withdrawType == "trx"? "custom-select__same-as-selected" : ""].join(' ')} 
                        data-token="trx" onClick={event=> selectToken("trx")}
                        >TRX</div>
                        <div 
                        className={["custom-select__item", userStorage.withdrawType == "ww3"? "custom-select__same-as-selected" : ""].join(' ')} 
                        data-token="ww3"  onClick={event=> selectToken("ww3")}>WW3</div>
                     </div>}
                  </div>
               </fieldset>
               <fieldset className="withdraw__swap-form-fieldset">
                  <label className="withdraw__swap-form-label">
                  <span className="withdraw__swap-form-label-text">To whom</span>
                  <input
                     className="withdraw__swap-form-input"
                     type="text"
                     name="target"
                     value={reciver}
                     onChange={event=>set_Reciver(event.target.value)}
                     />
                  </label>
               </fieldset>
               <fieldset className="withdraw__swap-form-fieldset">
                  <button className="button"
                  style={{opacity:actionText() == "TRANSFER"?  1 : 0.5}}
                  onClick={event=>handleButtonClick()}
                  >{actionText()}</button>
               </fieldset>
            </form>
         </div>
      </div>
   </section>
</main>
</>
    );
};

export default WithdrawPage;