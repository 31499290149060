import React, { useState } from 'react';
import QuestTimer from './QuestTimer';

const QuestsPage = ({data, set_openQuestPage, sendQuest}) => {
   //const [quests, set_quests] = useState(data?.quests? data?.quests : [{}])
   const quests = data?.quests? data?.quests : [{}]
   const statusStyle = (status) =>{
      if (status == "active") {
         return "quests__item"
      } else if (status == "done"){
         return "quests__item quests__item_done"
      } else {
         return "quests__item quests__item_expired"
      }
   }
   const buttonText = (status) =>{
      if (status == "active"){
         return "Follow"
      } else if (status == "done"){
         return "Done"
      } else {
         return "Expired"
      }
   }
   return (
   <main className="main">
      <section className="quests">
         <div className="container">
            <div className="quests__top">
               <a className="quests__back"  onClick={event=>set_openQuestPage(false)}></a>
               <h2 className="quests__title">QUESTS</h2>
            </div>
            <div className="quests__content">
               {false && quests.map(p=>
               <div className={statusStyle(p.status)} key={p.soc_quest_id}>
                  <div className="social__quests-item-top">
                     <div className="social__quests-item-top-points">{p.points} point</div>
                     <div className="social__quests-item-top-type">follow</div>
                     <div className="social__quests-item-top-time">
                        {p.left && p.left > 0 ?
                        <QuestTimer initialMillis = {p.left * 1000}/>
                        : 
                        '00:00'
                        }      
                     </div>
                  </div>
                  <div className="social__quests-item-body">
                     <div className="social__quests-item-text">
                        JUST FOLLOW ITS CHANNEL - <a href={p.twt_link} target='_blank'>{p.twt_link}</a>
                     </div>
                     <div className="social__quests-item-controls">
                        <button className="button" ><a style={{color:'white'}} href={p.twt_link} target='_blank'>{buttonText(p.status)}</a></button>
                     </div>
                  </div>
               </div>
               )}
               {quests.map(p=>
               <div className={statusStyle(p.status)}>
                  <div className="quests__item-top">
                     <div className="quests__item-top-points">{p.points} point</div>
                     <div className="quests__item-top-type">follow</div>
                     <div className="quests__item-top-time">   {p.left && p.left > 0 ?
                        <QuestTimer initialMillis = {p.left * 1000}/>
                        : 
                        '00:00'
                        }  
                     </div>
                  </div>
                  <div className="quests__item-body">
                     <div className="quests__item-text">
                        JUST FOLLOW ITS CHANNEL - <a href={p.twt_link} target='_blank'>{p.twt_link}</a>
                     </div>
                     <div className="quests__item-controls">
                        <button className="button" onClick={event=>sendQuest(p.soc_quest_id)} >
                        <a style={{color:'white'}}                           
                        //href={p.twt_link} 
                        target='_blank'>{buttonText(p.status)}</a></button>
                     </div>
                  </div>
               </div>
               )}
               <br/><br/><br/>
            </div>
         </div>
      </section>
   </main>
    );
};

export default QuestsPage;