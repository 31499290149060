import React, { useState, useContext, useEffect } from "react";
import SocialInfoBlock       from './SocialInfoBlock';
import SocialQuestsBlock     from './SocialQuestsBlock';
import SocialNewcomersBlock  from './SocialNewcomersBlock';
import SocialFilter          from './SocialFilter';
import SocialUsers           from './SocialUsers';
import Modal_SocialFaq       from './modals/Modal_SocialFaq';
import Modal_SocialFollowing from './modals/Modal_SocialFollowing';
import Modal_SocialUser      from './modals/Modal_SocialUser';
import QuestsPage            from "./QuestsPage";
import { fetchEntry, fetchUsers, followUser, executeQuest } from '../../../http/api_social_platform';
import { NotificationContext } from '../../../contex';

function updateQuestStatus(data, socQuestId) { // функция для пометки квеста как выполненого
  const updatedQuests = data.quests.map(quest => 
      quest.soc_quest_id == socQuestId ? { ...quest, status: 'done' } : quest
  );
  return { ...data, quests: updatedQuests };
}

const SocialPlatformPage = () => {

const [data, set_data] = useState({})
const [isLoaded, set_isLoaded] = useState(false)
const [openQuestPage, set_openQuestPage] = useState(false)
const {
   Notification
} = useContext(NotificationContext) // Всплывающие сообщения
// переменные для открытия попапов
const [show_Modal_SocialFaq, set_show_Modal_SocialFaq] = useState(false)
const [show_Modal_SocialUser, set_show_Modal_SocialUser] = useState(false)
const [show_Modal_SocialFollowing, set_show_Modal_SocialFollowing] = useState(false)

const getData = async () => {
   try {
      const result = await fetchEntry();
      if (result.status == "OK") {
         set_isLoaded(true)
         set_data(result.data)
      }
      console.log(result)
   } catch (error) {
      console.log(error)
   }
}
useEffect(() => { // Получаем данные для верхнего блока
   getData()
}, [])
   
// Работа с блоком поиска юзеров 
const [sort_asc, set_sort_asc] = useState(false)
const [sort_col, set_sort_col] = useState('FOLLOWERS') // WW3 TOKEN, FOLLOWING, FOLLOWERS
const [hide_followed, set_hide_followed] = useState(false)
const [search_reptiloid, set_search_reptiloid] = useState('')
const [dataUsers, set_dataUsers] = useState([])

const getUsers = async () => {
   try {
      const result = await fetchUsers({
         sort_asc,
         sort_col,
         hide_followed,
         search_reptiloid
      });
      if (result.status == "OK") {
         set_dataUsers(result.data)
      }
      console.log(result)
   } catch (error) {
      console.log(error)
   }
}
useEffect(() => { // Получаем данные для верхнего блока    
   // притормаживаем вызов получения при изменении переменных чтобы не перегружать бэкенд запросами
   const handler = setTimeout(() => {
      console.log('getUsers()')
      getUsers()
   }, 1000);
   return () => {
      clearTimeout(handler);
   };
}, [sort_asc, sort_col, hide_followed, search_reptiloid])

// Работа с блоком квестов, чтобы не перегружать бэкенд перезагрузкой все страницы
// при успешной постановке квеста в очередь просто меняем данные на фронте
const changeStatus = (socQuestId) => {
  const newData = updateQuestStatus(data, socQuestId);
  set_data(newData); //console.log('newData',newData)
};
// Отправка квеста в очередь
const sendQuest = async (soc_quest_id) => {
  try {
     const result = await executeQuest({
        soc_quest_id
     });
     if (result.status == "OK") {
        Notification(result?.message, "success")
        changeStatus(soc_quest_id) // если успешно то помечаем квест как выполненный
     } else {
        Notification(result?.message, "error")
     }
     
  } catch (error) {
     console.log(error)
  }
}
// Отправка квеста в очередь
const sendFollow = async (soc_user_id) => {
  try {
    console.log(soc_user_id)
     const result = await followUser({soc_user_id});
     if (result.status == "OK") {
        Notification(result?.message, "success")
        //changeStatus(soc_quest_id) // если успешно то помечаем квест как выполненный
     } else {
        Notification(result?.message, "error")
     }
     console.log(result)
  } catch (error) {
     console.log(error)
  }
}

return (
<>
{isLoaded && <>
{show_Modal_SocialFaq       && <Modal_SocialFaq set_show_Modal={set_show_Modal_SocialFaq}/>}
{show_Modal_SocialUser      && <Modal_SocialUser set_show_Modal={set_show_Modal_SocialUser}/>}
{show_Modal_SocialFollowing && <Modal_SocialFollowing set_show_Modal={set_show_Modal_SocialFollowing}/>}

{openQuestPage? <QuestsPage data = {data} set_openQuestPage={set_openQuestPage} sendQuest={sendQuest} /> // основная страница платформы и страница квестов переключаемы мужду собой 
:
<main className="main">
   <section className="social">
      <div className="container">
         <SocialInfoBlock data = {data} 
            set_show_Modal_SocialFaq={set_show_Modal_SocialFaq} 
            set_show_Modal_SocialUser={set_show_Modal_SocialUser} 
            set_show_Modal_SocialFollowing={set_show_Modal_SocialFollowing}/>
         <SocialQuestsBlock data = {data} set_openQuestPage={set_openQuestPage} sendQuest={sendQuest}  />
         <SocialNewcomersBlock data = {data} />
         <SocialFilter 
            sort_asc = {sort_asc} set_sort_asc = {set_sort_asc} 
            sort_col = {sort_col} set_sort_col = {set_sort_col} 
            hide_followed = {hide_followed} set_hide_followed = {set_hide_followed}
            search_reptiloid = {search_reptiloid} set_search_reptiloid = {set_search_reptiloid} />
         <SocialUsers
            dataUsers = {dataUsers}
            sendFollow = {sendFollow}
         />
      </div>
   </section>
</main>
}
</>}
</>

    );
};

export default SocialPlatformPage;