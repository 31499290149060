import React, { useState, useContext, useEffect, useRef } from "react";

const SwapSelect = ({setDirectSwap, selectedToken }) => {
    //const [selectedToken, set_SelectedToken] = useState('trx')
    const [show_SelectToken, set_show_SelectToken] = useState(false)

    const openSelectToken = () =>{
        set_show_SelectToken(!show_SelectToken)
     }
    
     // Обработчик закрытия селекта при клике вне элемента
     const elementRef = useRef(null);
     useEffect(() => {
       // Функция для обработки кликов по документу
       const handleClickOutside = (event) => {
           // Проверяем, что целевой элемент существует и клик был вне этого элемента
           if (elementRef.current && !elementRef.current.contains(event.target)) {
            //console.log('Клик произошел вне компонента!');
           // console.log('elementRef', elementRef);
           set_show_SelectToken(false)
           }
       };

       // Добавляем обработчик события `click` на весь документ
       document.addEventListener('click', handleClickOutside);

       // Удаляем обработчик при размонтировании компонента
       return () => {
           document.removeEventListener('click', handleClickOutside);
       };
   }, []);   
    return (
<div className="custom-select" data-value={selectedToken} onClick={event=> openSelectToken()} ref={elementRef}>
   <div className="custom-select__selected active"
   
   >
    <span className="custom-select__selected-text">{selectedToken.toUpperCase()}</span>
   </div>
   {show_SelectToken && <div className="custom-select__items ">
      <div className="custom-select__item" data-token="trx" 
      onClick={event=> setDirectSwap("trx")}>TRX</div>
      <div className="custom-select__item custom-select__same-as-selected" data-token="ww3"
      onClick={event=> setDirectSwap("ww3")}
      >WW3</div>
   </div>}
   <div className="custom-select__overlay custom-select__hide"></div>
</div>
    );
};

export default SwapSelect;