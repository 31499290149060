import React from 'react';

const ModalCompleteWithdraw = ({   
   YouSend,
   targetToken,
   TxFee,
   set_show_Modal,
   confirm_swap,
   thisWallet,
   reciver
}) => {
const confirm = () =>{
      set_show_Modal(false)
      confirm_swap()
}
    return (
<div class="modal modal_complete-withdraw active"  onClick={event=>set_show_Modal(false)}>
   <div class="modal__body" onClick={event =>event.stopPropagation()}>
      <h3 class="modal__title">Complete withdraw</h3>
      <p class="modal__description">
         Before swapping, please double-check that all your data is entered correctly. Play it safe!
      </p>
      <ul class="modal__list">
         <li class="modal__list-item">
            <b>You send:</b>
            <span> { YouSend }</span>
            <span> { targetToken }</span>
         </li>
         <li class="modal__list-item">
            <b>This wallet</b>
            <span> { reciver }</span>
         </li>
         <li class="modal__list-item">
            <b>Transaction fee:</b>
            <span> { TxFee }</span>
            <span>TRX</span>
         </li>
         {false && <li class="modal__list-item">
            <b>You will earn:</b>
            <span>0</span>
            <span>POINTS</span>
         </li>}
      </ul>
      <div class="modal__buttons">
         <button class="button button_confirm" onClick={event=>confirm()}>confirm</button>
         <button class="button button_light"   onClick={event=>set_show_Modal(false)}>cancel</button>
      </div>
   </div>
</div>
    );
};

export default ModalCompleteWithdraw;