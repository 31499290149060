import React, {useState, useContext, useEffect} from "react";
import { useNavigate} from "react-router-dom";
import { login, logout } from "../http/auth_user";
import { UserContext } from '../contex';
import jwt_decode from "jwt-decode"

const Tpl_login = ({page}) => {
/** при логине страницы узнаем если пользователь имеет действительный токен то подтягиваем все данные */
const {user, setUser} = useContext(UserContext)
const navigate = useNavigate();
return(
<>

{page}
</>
    )
}

export default Tpl_login;