import React from 'react';

const Game_2_Card = () => {
    return (
    <div className="games__item games__item_disabled games__item_soon">
        <figure className="games__item-figure">
           <img className="games__item-figure-image" src="./assets/game-image.webp" alt="game" />
        </figure>
        <div className="games__item-soon">Soon</div>
     </div>
    );
};

export default Game_2_Card;