import React, { useState, useEffect, useRef } from "react";
import plateImage from "./assets/images/IMG_7145.PNG";
import Styles from "./GameMenu.module.css";

const GameMenu = ({set_numberGamePage}) => {
    const [startTime, setStartTime] = useState(5);

    // Используем useRef для хранения идентификатора таймера
    const timerRef = useRef(null);

    useEffect(() => {
        // Функция, уменьшающая startTime
        const countdown = () => {
            setStartTime(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        };

        // Если startTime еще не 0, создаем таймер
        if (startTime > 0) {
            timerRef.current = setTimeout(countdown, 1000);
        }
        // Если startTime  0, то вызываем колбэк функцию через которую запускается игра
        if (startTime == 0) {
          //  set_launchMenu(false)
        }

        // Чистим таймер при размонтировании компонента
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [startTime]); // Зависимость от startTime для перезапуска useEffect

    return (
        <div className="main">
            <div className="background paused"
              style={{
                position: 'absolute',
                width: '300%',
                height: '8%',
                top: 0,
                left: 0,
                backgroundImage: 'url("/assets/images/IMG_7137.PNG")', // Укажите абсолютный путь
                backgroundRepeat: 'repeat-x',
                backgroundSize: '600px',
                zIndex: 2
              }}
            ></div>
            <div className="game__area">
                <h1 className="play_head"></h1>
                <button className="start__button"
                 onClick={event=>set_numberGamePage(2)}
                >PLAY</button>
                <div className={[Styles.plate, "plate"].join(' ')} 
                style={{top: '50%', 
                    left: '40%', 
                  //  transform: 'rotate(15deg) !important'
                }}
               
                >
                    <img src={plateImage} alt="plate"/>
                </div>
            </div>
        </div>
    );
};

export default GameMenu;
