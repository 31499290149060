import React, { useState, useEffect } from 'react';
import { Game } from "./Game";
import styled from 'styled-components';
import GameMenu from './GameMenu';
import GameResult from './GameResult';
import { fetchEntrys, saveEntry } from '../../../../http/api_games';


const Game_1_Page = () => {

const GameWrapper = styled.div`
    html, body {
touch-action: manipulation; 
user-select: none;         
-webkit-user-select: none;  
-webkit-touch-callout: none; 
}
*{
user-select: none;
-webkit-touch-callout: none;
}

.main {
position: relative;
width: 100vw;
height: 100vh;
border: 2px solid black;
overflow: hidden;
margin: 0 auto;
background-color: gray;
}
.background {
position: absolute;
width: 300%;
height: 8%;
top: 0;
left: 0;

background-repeat: repeat-x;
background-size: 600px;
z-index: 2;
}
.background.paused {
animation-play-state: paused;
}
.game__area {
position: relative;
width: 100%;
height: 100%;
display: flex;
touch-action: none;
}

.plate {
position: absolute;
width: 100px;
height: auto;
& img {
  width: 100%;
  height: 100%;
}
}
.building {
position: absolute;
top: 50px;
}
.building.bottom {
top: unset;
bottom: 0;
}
.game-over {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: rgba(0, 0, 0, 0.7);
color: white;
padding: 20px;
border-radius: 10px;
text-align: center;
gap: 20px;
z-index: 10;
width: 80%; 
max-width: 400px; 
}
.start__button {
position: absolute;
top: 60%;
left: 50%;
transform: translate(-50%, -50%);
background-color: rgba(245, 12, 12, 0.7);
color: white;
padding: 20px 50px;
border-radius: 10px;
text-align: center;
cursor: pointer;
border: 3px solid black;
font-size: 26px;
}

.play_head {
font-size: 32px;
color: white;
position: absolute;
top: 30%;
left: 10%;
}

.timer {
border-radius: 16px;
position: absolute;
top: 10%;
left: 50%;
transform: translate(-50%, -50%);
padding: 10px;
font-size: 20px;
color: white;
background-color: red;
z-index: 3;
}

.play_again {
padding: 20px 50px;
border: 3px solid black;
border-radius: 16px;
font-size: 26px;
color: white;
background-color: crimson;
}

.menu {
background-color: gainsboro;
}

.countdown {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-size: 48px;
color: white;
z-index: 10;
}

@keyframes moveBackground {
from {
  transform: translateX(0);
}
to {
  transform: translateX(-50%);
}
}
  `;

  const [timeSpent, setTimeSpent] = useState(null);
  const [numberGamePage, set_numberGamePage] = useState(1);

  const saveResultGame = async (time) => {
    try {
      const result = await saveEntry({time: time, system_game_name: 1}); 
      console.log(result); 
    } catch (error) {
      console.log(error)
    }
  }; 
  
  const handleGameEnd = (time) => {
    setTimeSpent(time);
    set_numberGamePage(3);
    saveResultGame(time);
    console.log(`Time spent on game: ${time} seconds`);
  }; 

  useEffect(() => {
    
  }, [timeSpent]);


  const renderGamePage = ()=>{
    if (numberGamePage == 1) {
      return <GameWrapper>
        {false && <button onClick={event=>saveResultGame(10)}>ТЕСТ</button>}
        {<GameMenu set_numberGamePage = {set_numberGamePage} />}</GameWrapper> 
    } else if (numberGamePage == 2) {
      return <GameWrapper>{<Game difficultyLevel="hard" onGameEnd={handleGameEnd} />}</GameWrapper>  
    } else if(numberGamePage == 3){
      return <GameWrapper>{<GameResult finalScore = {timeSpent} />}</GameWrapper> 
    }
  }
  
    return (
      <>
      {renderGamePage()}      
      </>
    );
};

export default Game_1_Page;