import { createContext } from "react";

export const UserContext = createContext(null);
export const UserStorageContext = createContext(null);
export const BalanceStorageContext = createContext(null);
export const NotificationContext = createContext(null);
export const InitDataContext = createContext(null);






