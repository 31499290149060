import {$authHost} from "./index"

export const fetchEntrys = async () => {
    try {
        const {data} = await $authHost.get('api/games/games', { params: {}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const saveEntry = async ( _data) => {
    try {
        const {data} = await $authHost.post('api/games/save_game', { params: {data: _data}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}











