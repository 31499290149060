import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from '../../../utils/routes';
import { fetchEntry } from '../../../http/api_social_connect';
import { NotificationContext } from '../../../contex';

const SocialConnectPage = () => {
  const { Notification } = useContext(NotificationContext) // Всплывающие сообщения
  //const hostUrl = process.env.REACT_APP_WEBAPP_SITE_URL
  const navigate = useNavigate();
  const [ auth_url, set_auth_url ] = useState('')
  const [ isLoaded, set_isLoaded ]  = useState(false)

  const checkConnect = async () => { 
    try {
      const result = await fetchEntry(); 
      if (result?.data == false) {
        set_isLoaded(true)

        // Формируем адрес для перехода при авторизации через твитер
        let token =  sessionStorage.getItem('token')?.length > 0?  sessionStorage.getItem('token') : localStorage.getItem('token')  
        const url = `${result.hostUrl}api/auth/twitter?bearer=${token}`
        set_auth_url(url)
      }  else {
        navigate(ROUTES.SOCIALPLATFORM);
      }      
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }
 
  useEffect(()=>{ 
 
    // Получаем проверку залогинен или нет данный юзер в системе
    checkConnect()
  },[])


const copy_text = (text) => {
    navigator.clipboard.writeText(text).then(() => {console.log('copy_text!');}).catch(err => { console.log('Something went wrong', err);});
}

const copy_auth_link = () => {
    copy_text(auth_url);
    Notification("Authorization link copied!", "success")
}
    
return (<>
{isLoaded?
  <main className="main">
    <section className="social-connect">
      <div className="container">
        <h2 className="social-connect__title">
          Before using all the features of the social platform, you must connect your X
        </h2>
        <figure className="social-connect__figure">
          <img
            className="social-connect__figure-image"
            src="./assets/social-connect.png"
            alt="social connect"
          />
        </figure>
        <p className="first-step__text">
        1) Follow the link to log in through your Twitter account <br/>
        2) then log in to our social platform again. <br/>
        </p><br/>

        <a className="social-connect__controls"  
          href={auth_url} target='_blank'><button className="button" >Open link in browser</button>
        </a>  
        <div className="social-connect__controls" onClick={event=>copy_auth_link()} style={{marginTop:'5px'}}> 
          <button className="button">Copy link</button>
        </div>
        
          
     
      </div>
    </section>
  </main>
  :
<main className="main">

</main>
   }
</>
    );
};

export default SocialConnectPage;