import React, { useState, useContext, useEffect } from "react";
import { UserContext, UserStorageContext, BalanceStorageContext, NotificationContext } from '../../../contex';
import { fetchEntry, fetchSeed, sendTransfer, sendSwap } from "../../../http/api_wallets";
import { walletShortener, gweiToEth, sunToTrx, numberShortener, PointsFormat} from "../../../utils/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import PointsTotalModal from "./PointsTotalModal";
import YourRefferalsModal from "./YourRefferalsModal";

const ProfilePage = () => {
const { userStorage, setUserStorage } = useContext(UserStorageContext) // Хранилище данных
const { balanceStorage, setBalanceStorage }  = useContext(BalanceStorageContext) // Хранилище данных
const { Notification } = useContext(NotificationContext) // Всплывающие сообщения

const getWalletInfo = async() => {//  при загрузке страницы получаем данные пользователя
    try {
      const result = await fetchEntry(); // Для использования на странице
      setUserStorage(prevState => ({
        ...prevState,
        userData: result?.data, // Обновляем userData
      }));

      setBalanceStorage(prevState => ({// Обновляем balanceStorage
        ...prevState,
        trx_balance: result?.data?.trx_balance? result?.data?.trx_balance : 0,
        ww3_balance: result?.data?.ww3_balance? result?.data?.ww3_balance : 0, 
      }));  

      console.log(result)
    } catch (error) {
      console.log(error)
    }
}
useEffect(() => {
    getWalletInfo();
    console.log('getWalletInfo');
}, []);
const copy_text = (text) => {
    navigator.clipboard.writeText(text).then(() => {console.log('copy_text!');}).catch(err => { console.log('Something went wrong', err);});
}
const copy_wallet = (wallet) => {
    copy_text(wallet);
    Notification("Wallet address copied!", "success")
} 
const copy_ref_link = () => {
    const chatId = userStorage.userData.chat_id
    const bot_name = process.env.REACT_APP_TG_BOT_NAME
    const refLink = `http://t.me/${bot_name}?startapp=${chatId}`
    copy_text(refLink);
    Notification("Referral link copied!", "success")
}
//кнопки для открытия доп.информации в блоках 
const [PointsTotalToggle, set_PointsTotalToggle] = useState(false)
const [YourRefferalsToggle, set_YourRefferalsToggle] = useState(false)
// переменные для открытия попапов
const [show_PointsTotalModal, set_show_PointsTotalModal] = useState(false)
const [show_YourRefferalsModal, set_show_YourRefferalsModal] = useState(false)

return (
<>
<main class="main">
{show_PointsTotalModal && <PointsTotalModal set_show_PointsTotalModal={set_show_PointsTotalModal}/>}
{show_YourRefferalsModal && <YourRefferalsModal set_show_YourRefferalsModal={set_show_YourRefferalsModal}/>}
<section className="profile">
   <div className="container">
      <div className="profile__user">
         <figure className="profile__user-photo">
            <img className="profile__user-photo-image" src="./assets/user-photo.jpg" alt="user" />
         </figure>
         <div className="profile__user-info">
            <div className="profile__user-info-name"
               onClick={event=>copy_wallet(userStorage.userData.account)}
               >{walletShortener(userStorage.userData.account)}
            </div>
            <div className="profile__user-info-account">
               <span className="profile__user-info-account-text">account verified</span>
               <a className="profile__user-info-account-link"></a>
            </div>
         </div>
      </div>
      <div  className={["profile__points accordion",  PointsTotalToggle? "active" : ""  ].join(' ')}>
      <div className="profile__points-main accordion__main" onClick={event=> set_PointsTotalToggle(!PointsTotalToggle)} >
         <div className="profile__points-main-title">POINTS TOTAL</div>
         <div className="profile__points-main-value"> {PointsFormat(
              Number(userStorage.userData.points_wallet)
            + Number(userStorage.userData.points_game)
            + Number(userStorage.userData.points_social)
            + Number(userStorage.userData.points_social_active)
            + Number(userStorage.userData.points_social_2)
            + Number(userStorage.userData.points_ref_1)
            + Number(userStorage.userData.points_ref_2)
         )}</div>
      </div>
      <div className="profile__points-panel accordion__panel" style={{ maxHeight: PointsTotalToggle && '199px'}}>
      <ul className="profile__points-panel-info">
         <li className="profile__points-panel-info-item">
            <span className="profile__points-panel-info-item-text">Wallet:</span>
            <span className="profile__points-panel-info-item-value"> {PointsFormat(userStorage.userData.points_wallet)} POINTS</span>
         </li>
         <li className="profile__points-panel-info-item">
            <span className="profile__points-panel-info-item-text">Social platform:</span>
            <span className="profile__points-panel-info-item-value"> {PointsFormat(userStorage.userData.points_social)} POINTS</span>
         </li>
         <li className="profile__points-panel-info-item">
            <span className="profile__points-panel-info-item-text">Game:</span>
            <span className="profile__points-panel-info-item-value"> {PointsFormat(userStorage.userData.points_game)} POINTS</span>
         </li>
         <li className="profile__points-panel-info-item">
            <span className="profile__points-panel-info-item-text">Refferal:</span>
            <span className="profile__points-panel-info-item-value"> {PointsFormat(
                 Number(userStorage.userData.points_ref_1)
               + Number(userStorage.userData.points_ref_2)
            )} POINTS</span>
         </li>
      </ul>
      <div className="profile__points-panel-question">
         <button className="profile__points-panel-question-button" onClick={event=>set_show_PointsTotalModal(true)} >
         How many points do you get for what?
         </button>
      </div>
   </div>
   </div>
   <div className={["profile__referrals accordion",  YourRefferalsToggle? "active" : ""  ].join(' ')}>
   <div className="profile__referrals-main accordion__main" onClick={event=>set_YourRefferalsToggle(!YourRefferalsToggle)}>
      <div className="profile__referrals-main-title">YOUR REFERRALS</div>
      <div className="profile__referrals-main-value">{PointsFormat(
               Number(userStorage.userData.referrals_1) + 
               Number(userStorage.userData.referrals_2)
            )}</div>
   </div>
   <div className="profile__referrals-panel accordion__panel"
   style={{ maxHeight: YourRefferalsToggle && '275px'}}
   >
   <ul className="profile__referrals-panel-info">
      <li className="profile__referrals-panel-info-item">
         <span className="profile__referrals-panel-info-item-text">Referrals type one:</span>
         <span className="profile__referrals-panel-info-item-value"> {PointsFormat(
               Number(userStorage.userData.referrals_1)
            )}</span>
      </li>
      <li className="profile__referrals-panel-info-item">
         <span className="profile__referrals-panel-info-item-text">they earned you:</span>
         <span className="profile__referrals-panel-info-item-value"> {PointsFormat(
               Number(userStorage.userData.points_ref_1)
            )} Points</span>
      </li>
      <li className="profile__referrals-panel-info-item">
         <span className="profile__referrals-panel-info-item-text">Referrals type two</span>
         <span className="profile__referrals-panel-info-item-value"> {PointsFormat(
               Number(userStorage.userData.referrals_1_active)
            )}</span>
      </li>
      <li className="profile__referrals-panel-info-item">
         <span className="profile__referrals-panel-info-item-text">they earned you:</span>
         <span className="profile__referrals-panel-info-item-value"> {PointsFormat( 
            Number(userStorage.userData.points_social_active)
            )} Points</span>
      </li>
      <li className="profile__referrals-panel-info-item">
         <button className="button"
            onClick={event=>copy_ref_link()}
         >copy referral link</button>
      </li>
   </ul>
   <div className="profile__referrals-panel-question">
      <button className="profile__referrals-panel-question-button"
      onClick={event=>set_show_YourRefferalsModal(true)}
      >
      What kind of referrals are there and how much do they get paid for?
      </button>
   </div>
   </div>
   </div>
   <div className="profile__nav">
      <a className="profile__nav-link profile__nav-link_blocked" >
      <span className="profile__nav-link-text">STAKING</span>
      <img className="profile__nav-link-bg" src="./assets/staking.jpg" alt="staking"/>
      </a>
      <a className="profile__nav-link profile__nav-link_blocked" >
      <span className="profile__nav-link-text">SOCIAL PLATFORM</span>
      <img className="profile__nav-link-bg" src="./assets/social-platform.jpg" alt="staking"/>
      </a>
      <a className="profile__nav-link profile__nav-link_blocked" >
      <span className="profile__nav-link-text">NFT</span>
      <img className="profile__nav-link-bg" src="./assets/nft.jpg" alt="staking"/>
      </a>
   </div>
   </div>
</section>
</main>
</>
    );
};

export default ProfilePage;