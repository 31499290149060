import React, { useState, useEffect } from 'react';

const SocialFilter = ({
   sort_col,
   set_sort_col,
   search_reptiloid,
   set_search_reptiloid,
   sort_asc,
   set_sort_asc,
   hide_followed,
   set_hide_followed
 }) => {
   const [showFilter, set_showFilter] = useState(false);
   const [searchInput, setSearchInput] = useState(search_reptiloid);
 
   useEffect(() => {// притормаживаем изменение переменной чтобы не перегружать бэкенд запросами
     const handler = setTimeout(() => {
       console.log(searchInput)
       if (searchInput.length > 3 || searchInput.length == 0) { // вызываем изменение только если введено больше 3х символов
         set_search_reptiloid(searchInput);
       }
     }, 1000);
     return () => {
       clearTimeout(handler);
     };
   }, [searchInput]);

   return (
<div className="social__filter" id="socialFilter">
   <div className="social__filter-search">
      <input
         className="social__filter-search-input"
         type="search"
         placeholder="Search reptiloid name..."
         value={searchInput}
         onChange={(e) => setSearchInput(e.target.value)}
      />
   </div>
   <div className= {`social__filter-sort  ${showFilter? "active" : ""}`} onClick={event=>set_showFilter(!showFilter)}>
   <div className="social__filter-sort-status" >
      <div className="social__filter-sort-status-type">{sort_col}</div>
      <div className= {`social__filter-sort-status-direction  ${sort_asc? "" : "reversed"}`} >
   </div>
</div>
<div className="social__filter-sort-dropdown ">
   <label className="social__filter-sort-radio">
   <input onClick={event=>set_sort_col('WW3 TOKEN')}
   className="social__filter-sort-radio-input"
   type="radio"
   name="socialSortType"
   value="WW3 TOKEN"                    
   />
   <span className="social__filter-sort-radio-text">WW3 TOKEN</span>
   </label>
   <label className="social__filter-sort-radio">
   <input onClick={event=>set_sort_col('FOLLOWING')}
   className="social__filter-sort-radio-input"
   type="radio"
   name="socialSortType"
   value="FOLLOWING"
   />
   <span className="social__filter-sort-radio-text">FOLLOWING</span>
   </label>
   <label className="social__filter-sort-radio">
   <input onClick={event=>set_sort_col('FOLLOWERS')}
   className="social__filter-sort-radio-input"
   type="radio"
   name="socialSortType"
   value="FOLLOWERS"
   />
   <span className="social__filter-sort-radio-text">FOLLOWERS</span>
   </label>
   <div className="social__filter-sort-direction">
      <label className="social__filter-sort-direction-checkbox">
      <input className="social__filter-sort-direction-checkbox-input" 
         type="checkbox" 
         checked={!sort_asc}
         onChange={(e) => set_sort_asc(e.target.checked?  false : true)}
      />
      <span className="social__filter-sort-direction-checkbox-toggler"></span>
      </label>
   </div>
</div>
</div>
<div className="social__filter-followed">
   <div className="social__filter-followed-text">HIDE FOLLOWED</div>
   <label className="social__filter-followed-checkbox">
   <input
      className="social__filter-followed-checkbox-input"
      type="checkbox"
      checked={hide_followed}
      onChange={(e) => set_hide_followed(e.target.checked? true : false)}
   />
   </label>
</div>
</div>
    );
};

export default SocialFilter;