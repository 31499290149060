import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { UserContext, UserStorageContext, BalanceStorageContext, NotificationContext, InitDataContext } from './contex';
import AppRouter from './components/AppRouter';
import { login } from './http/auth_user';
import jwt_decode from "jwt-decode";
import AlertNotification from './components/UI/AlertNotification';
import { decodeInitData } from './utils/decodeInitData';

function App() {
  // Инициируем телеграм для работы
  const tg = window.Telegram.WebApp; 
  const [balanceStorage, setBalanceStorage] = useState({
    trxInUSDTCourse: 0.151129,
    ww3InUSDTCourse: 373.582219, // курс за 1 миллион токенов
    trx_balance: 0,
    ww3_balance: 0
   }) 
  const [userStorage, setUserStorage] = useState({
   withdrawType: 'trx', // 0 = trx,   
   userData: { // Данные профиля пользователя получаемые с бэка на странице wallet  
    "chat_id": "",
    "username": "",
    "first_name": "",
    "is_created": 1,
    "points_wallet": 0,
    "points_game": 0,
    "points_social": 0,
    "points_ref_1": 0,
    "points_ref_2": 0,
    "referrals_1": 0,
    "referrals_2": 0,    
    "account": "",
    "trx_balance": "0",
    "ww3_balance": "0"
    }    // 
  })
  const [user, setUser] = useState({ chat_id: 0, first_name: '', last_name: '', username: '',  language_code: 'ru',
    allows_write_to_pm: false,   is_created: 0,  isAuth: false, })
  
    useEffect(() => {
      if (tg && !user?.allows_write_to_pm) {
         try {
            tg.requestWriteAccess().then((result) => {
               if (result) {
                  console.log('Permission granted!');
               } else {
                  console.log('Permission denied.');
               }
            }).catch((error) => {
               console.log('Error requesting permission:', error);
            });
         } catch (error) {
            console.log(error)
         }
      }
   }, [user])
  
  const [initData ,set_initData] = useState({}) // initData для учета рефералов и прочего  которую экспортируем в InitDataContext
  useEffect(()=>{
    tg.ready(); 
    // console.log('user:', user)
    // console.log('tg:', tg)
    // console.log('tg.initDataUnsafe?.user:', tg.initData)  
    // Извлекаем параметры из InitData для учета рефералов и прочего  
  
    const _initData = decodeInitData(tg.initData)
    set_initData(_initData)
    
  },[])

  const initUser = (token) => { // инициируем юзера в приложении
    let decode  = jwt_decode(token) // декодируем
    console.log(decode)
    if (decode?.chat_id) {// если юзер в токене записан то сохраняем данные юзера
      setUser({
        chat_id: decode.chat_id,
        first_name: decode.first_name,
        last_name: decode.last_name,
        username: decode.username,
        language_code: decode.language_code,
        allows_write_to_pm: decode.allows_write_to_pm,
        is_created: decode.is_created, 
        isAuth: true})
        // сохраняем в сессион сторадж  
        sessionStorage.setItem('token', token)
    }
  }
  const checkAuth = async () =>{
    if (window.Telegram?.WebApp) {// проверям вход ли это через телеграм      
      const initData = tg.initData;
     // console.log('initDataUnsafe', initDataUnsafe)  
     // console.log('Object.keys(initDataUnsafe)', Object.keys(initDataUnsafe)) 
      if (initData) {
        console.log('checkAuth')  
        try {
          const response = await login(initData)// авторизуемся
          console.log(response)  
          if (response?.token) {          
            initUser(response?.token) 
          } else {}
        } catch (error) {
          console.log(error)
        }
      }
    }  
  }

  /** при загрузке страницы узнаем если пользователь имеет действительный токен то подтягиваем все данные */
  useEffect(() => {
    try { 
        let token = sessionStorage.getItem('token')      
        if (process.env.REACT_APP_MOK_AUTH == 'true' && true) {// ONLYDEVELOPMENT
          const devToken = process.env.REACT_APP_MOK_JWT
          token = devToken
          // sessionStorage.setItem('token', devToken) // ONLYDEVELOPMENT
        }
        if (token?.length>10) {          
            console.log(token)    
            initUser(token)
        } else {
            checkAuth()
        }
     } catch (error){
        console.log(error)
     } 
     console.log(' useEffect checkAuth')     
  },[])

   /** Для компонента уведомления AlertNotification */
 const [messageAlert, setMessageAlert] = useState(''); // текст сообщения
 const [msgOpen, setMsgOpen] = useState(false); // показать/скрыть сообщение
 const [msgSeverity, setMsgSeverity] = useState("success"); // цвета сообщения, описан в компоненте AlertNotification

 const Notification = async (_messageAlert, _msgSeverity) => { // функция для вызова сообщения
    setMessageAlert(_messageAlert)
    setMsgSeverity(_msgSeverity)
    setMsgOpen(true)
    setTimeout(() => {
      setMsgOpen(false)
    }, 6000) // время автоматического скрытия сообщения
  }
  
  return (
<InitDataContext.Provider value = {{ initData }}> 
  <UserContext.Provider value = {{ user, setUser }}>
    <UserStorageContext.Provider value = {{ userStorage, setUserStorage }}>
      <BalanceStorageContext.Provider value = {{ balanceStorage, setBalanceStorage }}>
        <NotificationContext.Provider value = {{ Notification
          //, messageAlert, setMessageAlert, msgOpen, setMsgOpen, msgSeverity, setMsgSeverity 
          }}>
          <BrowserRouter>
          {<>
            {msgOpen && 
            <AlertNotification
              messageAlert = {messageAlert}
              open = {msgOpen}
              setOpen = {setMsgOpen}
              severity = {msgSeverity}
            />}
            <AppRouter/>
          </>}
          </BrowserRouter>
        </NotificationContext.Provider>
      </BalanceStorageContext.Provider>
    </UserStorageContext.Provider>
  </UserContext.Provider>
</InitDataContext.Provider>   
  );
}

export default App;
