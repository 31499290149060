import React, { useState, useRef, useEffect, useCallback } from "react";
import buildingTop1 from "./assets/images/top/IMG_7133.PNG";
import buildingTop2 from "./assets/images/top/IMG_7134.PNG";
import buildingTop3 from "./assets/images/top/IMG_7135.PNG";
import buildingTop4 from "./assets/images/top/IMG_7136.PNG";
import buildingTop5 from "./assets/images/top/IMG_7138.PNG";
import buildingBottom1 from "./assets/images/bottom/IMG_7139.PNG";
import buildingBottom2 from "./assets/images/bottom/IMG_7140.PNG";
import buildingBottom3 from "./assets/images/bottom/IMG_7142.PNG";
import buildingBottom4 from "./assets/images/bottom/IMG_7147.PNG";
import buildingBottom5 from "./assets/images/bottom/IMG_7148.PNG";
import buildingBottom6 from "./assets/images/bottom/IMG_7149.PNG";
import buildingBottom7 from "./assets/images/bottom/IMG_7150.PNG";
import buildingBottom8 from "./assets/images/bottom/IMG_7151.PNG";
import plateImage from "./assets/images/IMG_7145.PNG";
//import bgImage from "./assets/images/IMG_7137.PNG";
//import "./game.css";

const buildingPairs = [
  {
    top: { src: buildingTop2, width: 200, height: 200 },
    bottom: { src: buildingBottom5, width: 110, height: 110 },
  },
  {
    top: { src: buildingTop3, width: 120, height: 120 },
    bottom: { src: buildingBottom6, width: 160, height: 160 },
  },
  {
    top: { src: buildingTop1, width: 120, height: 120 },
    bottom: { src: buildingBottom7, width: 190, height: 190 },
  },
  {
    top: { src: buildingTop4, width: 170, height: 170 },
    bottom: { src: buildingBottom8, width: 170, height: 170 },
  },
  {
    top: { src: buildingTop3, width: 130, height: 130 },
    bottom: { src: buildingBottom1, width: 100, height: 100 },
  },{
    top: { src: buildingTop3, width: 90, height: 90 },
    bottom: { src: buildingBottom2, width: 130, height: 130 },
  },
  {
    top: { src: buildingTop3, width: 100, height: 100 },
    bottom: { src: buildingBottom3, width: 110, height: 110 },
  },
  {
    top: { src: buildingTop5, width: 200, height: 200 },
    bottom: { src: buildingBottom4, width: 160, height: 160 },
  },
];

const getRandomBuilding = (buildingImages) => {
  return buildingImages[Math.floor(Math.random() * buildingImages.length)];
};

export const Game = ({ difficultyLevel, onGameEnd }) => {
  const [platePosition, setPlatePosition] = useState(50);
  const [plateLeftPosition, setPlateLeftPosition] = useState(-40);
  const [buildings, setBuildings] = useState([]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isPlateCentered, setIsPlateCentered] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [finalScore, setFinalScore] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [isAscending, setIsAscending] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [countDown, setCountDown] = useState(0);
  const [isTouchRunning, setIsTouchRunning] = useState(false);
  

  const plateRef = useRef();
  const screenWidth = 600;
  const buildingInterval = 400;
  const gravity = 0.5;
  const jumpForce = 1;

  const buildingSpeed =
    difficultyLevel === "hard" ? 6 : difficultyLevel === "easy" ? 2 : 4;


    const generateBuildings = () => {
      let newBuildings = [];
      const buildingCount = 6;
    
      for (let i = 0; i < buildingCount; i++) {
        const randomPair = buildingPairs[Math.floor(Math.random() * buildingPairs.length)];
  
        newBuildings.push({
          topImage: randomPair.top.src,
          bottomImage: randomPair.bottom.src,
          topHeight: randomPair.top.height,
          bottomHeight: randomPair.bottom.height,
          topWidth: randomPair.top.width,
          bottomWidth: randomPair.bottom.width,
          leftPosition: screenWidth + i * buildingInterval,
        });
      }
      return newBuildings;
    };

  const startGame = useCallback(() => {
    setIsGameStarted(false);
    setCountDown(3);
    setElapsedTime(0);
    setIsGameOver(false);
    setBuildings(generateBuildings());

    const countdownInterval = setInterval(() => {
      setCountDown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval);
          setIsGameStarted(true);
          setStartTime(Date.now());
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (isGameStarted) {
      const interval = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [isGameStarted]);

  useEffect(() => {
    if (!isGameStarted || isGameOver) return;

    const moveBuildings = () => {
      setBuildings((prevBuildings) => {
        let updatedBuildings = prevBuildings.map((building) => ({
          ...building,
          leftPosition: building.leftPosition - buildingSpeed,
        }));

        updatedBuildings = updatedBuildings.filter(
          (building) => building.leftPosition > -100
        );

        const newBuildingsCount = 6 - updatedBuildings.length;
        if (newBuildingsCount > 0) {
          const lastLeftPosition =
            updatedBuildings.length > 0
              ? updatedBuildings[updatedBuildings.length - 1].leftPosition
              : screenWidth;

          for (let i = 0; i < newBuildingsCount; i++) {
            const randomPair = buildingPairs[Math.floor(Math.random() * buildingPairs.length)];

            updatedBuildings.push({
              topImage: randomPair.top.src,
              bottomImage: randomPair.bottom.src,
              topHeight: randomPair.top.height,
              bottomHeight: randomPair.bottom.height,
              topWidth: randomPair.top.width,
              bottomWidth: randomPair.bottom.width,
              leftPosition: lastLeftPosition + buildingInterval * (i + 1),
            });
          }
        }

        return updatedBuildings;
      });
    };

    const interval = setInterval(() => {
      moveBuildings();
      checkCollision();
    }, 30);

    return () => clearInterval(interval);
  }, [isGameStarted, buildingSpeed, screenWidth, isGameOver]);

  useEffect(() => {
    if (!isGameStarted) {
      const interval = setInterval(() => {
        setPlateLeftPosition((prev) => {
          if (prev >= 40) {
            clearInterval(interval);
            setIsPlateCentered(true);
            return 40;
          }
          return prev + 2;
        });
      }, 30);

      return () => clearInterval(interval);
    }
  }, [isGameStarted]);

  useEffect(() => {
    if (!isGameStarted || isGameOver) return;

    let velocity = 0;

    const updatePosition = () => {
      setPlatePosition((prev) => {
        let newPos = prev + velocity;

        if (isTouchRunning) {
          velocity = -jumpForce;
        } else {
          velocity = gravity;
        }

        if (newPos < 0) {
          newPos = 0;
          velocity = 0;
          endGame();
        }
        if (newPos > 100) {
          newPos = 100;
          velocity = 0;
          endGame();
        }

        setIsAscending(velocity < 0);
        return newPos;
      });

      checkCollision();
    };

    const handleTouchStart = (event) => {
      event.preventDefault();
      setIsTouchRunning(true);
      velocity = -jumpForce;
      setRotate(-15);
    };

    const handleTouchEnd = (event) => {
      event.preventDefault();
      setIsTouchRunning(false);
    };

    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        setIsTouchRunning(true);
      }
    };
    const handleKeyUp = (event) => {
      if (event.key === "ArrowUp") {
        setIsTouchRunning(false);
      }
    };
  

    const interval = setInterval(updatePosition, 30);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      clearInterval(interval);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isGameStarted, isGameOver, isTouchRunning]);

  useEffect(() => {
    if (isAscending) {
      setRotate(-15);
    } else {
      setRotate(15);
    }
  }, [isAscending]);

  const checkCollision = () => {
    const plateBounds = plateRef.current.getBoundingClientRect();

    buildings.forEach((building, index) => {
      const buildingElement = document.querySelectorAll(".building")[index];
      if (buildingElement) {
        const buildingBounds = buildingElement.getBoundingClientRect();

        if (
          plateBounds.left < buildingBounds.right - 20 &&
          plateBounds.right > buildingBounds.left + 30 &&
          plateBounds.top - 10 < buildingBounds.bottom - 20 &&
          plateBounds.bottom > buildingBounds.top + 25
        ) {
          endGame();
        }
      }
    });
  };

  const endGame = useCallback(() => {
    setIsGameOver(true);
    const totalTime = Math.round((Date.now() - startTime) / 1000);
    onGameEnd(totalTime);
    setFinalScore(totalTime);
    setPlatePosition(50);
    setPlateLeftPosition(-40);
     setIsTouchRunning(false);
    setBuildings([]);
  }, [onGameEnd, startTime]);

  // Автозапуск игры при монтировании компонента
  useEffect(()=>{
    startGame()
  },[])

  return (
    <div className="main">
      <div 
      style={{
        position: 'absolute',
        width: '300%',
        height: '8%',
        top: 0,
        left: 0,
        backgroundImage: 'url("/assets/images/IMG_7137.PNG")', // Укажите абсолютный путь
        backgroundRepeat: 'repeat-x',
        backgroundSize: '600px',
        zIndex: 2
      }}
        className={`background ${!isGameStarted || isGameOver ? "paused" : ""}`}
      ></div>
      <div className="game__area">
        {isGameStarted && !isGameOver && (
          <div className="timer">{`Your Score: ${elapsedTime} km`}</div>
        )}
        {countDown > 0 && !isGameStarted && (
          <div className="countdown">
            <h2>{countDown}</h2>
          </div>
        )}
        {!isGameStarted && countDown === 0 && (
          <>
            <h1 className="play_head"></h1>
            <button
              className="start__button"
              onClick={startGame}
              disabled={!isPlateCentered}
            >
              PLAY
            </button>
          </>
        )}

        <div
          className="plate"
          style={{
            top: `${platePosition}%`,
            left: `${plateLeftPosition}%`,
            transform: `rotate(${rotate}deg)`,
          }}
          ref={plateRef}
        >
          <img src={plateImage} alt="plate" />
        </div>

        {buildings.map((building, index) => (
          <React.Fragment key={index}>
            <div
              className="building"
              style={{ left: `${building.leftPosition}px` }}
            >
              <img
                style={{ width: `${building.topWidth}px`, height: "auto" }}
                src={building.topImage}
                alt="building"
              />
            </div>

            <div
              className="building bottom"
              style={{ left: `${building.leftPosition}px` }}
            >
              <img
                style={{ width: `${building.bottomWidth}px`, height: "auto" }}
                src={building.bottomImage}
                alt="building"
              />
            </div>
          </React.Fragment>
        ))}

        {isGameOver && (
          <div className="game-over">
            <h1>Your score: {finalScore} km</h1>
            <button
              className="play_again"
              onClick={() => {
                setIsGameOver(false);
                startGame();
              }}
            >
              PLAY AGAIN
            </button>
            <button className="play_again menu">MENU</button>
          </div>
        )}
      </div>
    </div>
  );
};