import React, { useState, useContext, useEffect } from "react";
import { walletShortener, formatBigSummToShortWrite, gweiToEth, formatPercent } from "../../../utils/utils";

const SocialInfoBlock = ({data, set_show_Modal_SocialFaq, set_show_Modal_SocialUser, set_show_Modal_SocialFollowing}) => {
   const [ pinnedUser, set_pinnedUser ] = useState(data?.pinnedUser? data?.pinnedUser : {})
    return (
<div className="social__info" id="socialInfo">
            <div className="social__info-user" >
               <figure className="social__info-user-photo">
                  <img
                     className="social__info-user-photo-image"
                     src={pinnedUser.twt_photos}
                     alt={pinnedUser.twt_username}
                     />
               </figure>
               <div className="social__info-user-name">{walletShortener(pinnedUser.account)}</div>
               <div className="social__info-user-income">+250P</div>
               <button className="social__info-user-more" onClick={event=>set_show_Modal_SocialUser(true)}></button>
            </div>
            <div className="social__info-following">
               <div className="social__info-following-percent social__info-following-percent_empty">
               {formatPercent(pinnedUser.following_percent)}%
               </div>
               <div className="social__info-following-title">FOLLOWING</div>
               <div className="social__info-following-income">+250000P</div>
               <button className="social__info-following-more" onClick={event=>set_show_Modal_SocialFollowing(true)}></button>
            </div>
            <div className="social__info-wallet">
               <div className="social__info-wallet-cash">{formatBigSummToShortWrite(gweiToEth(pinnedUser.ww3_balance))}</div>
               <div className="social__info-wallet-token">$WW3 TOKEN</div>
            </div>
            <div className="social__info-faq" >
               <b>How does it work?</b> Click here to find out all about points, the social platform
               and much more...
               <button className="social__info-faq-more" onClick={event=>set_show_Modal_SocialFaq(true)}></button>
            </div>
         </div>
    );
};

export default SocialInfoBlock;