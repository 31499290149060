import React, { useState, useContext, useEffect } from "react";
import { UserContext, UserStorageContext, BalanceStorageContext, NotificationContext  } from '../../../contex';
import { fetchEntry, fetchSeed, sendTransfer, sendSwap } from "../../../http/api_wallets";
import { walletShortener, gweiToEth, sunToTrx, numberShortener} from "../../../utils/utils";
import ModalPoints from "./ModalPoints";
import ModalExportSeed from "./ModalExportSeed";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import WalletSwapWidget from "./WalletSwapWidget";

const WalletPage = () => {
const { userStorage, setUserStorage } = useContext(UserStorageContext) // Хранилище данных
const { balanceStorage, setBalanceStorage }  = useContext(BalanceStorageContext) // Хранилище данных
const { Notification }  = useContext(NotificationContext) // Всплывающие сообщения

const [show_ModalPoints, set_show_ModalPoints] = useState(false)
const [show_ModalExportSeed, set_show_ModalExportSeed] = useState(false)

const getWalletInfo = async() => { // при загрузке страницы получаем данные пользователя
  try {
    const result = await fetchEntry(); // Для использования на странице
    setUserStorage(prevState => ({
      ...prevState,
      userData: result?.data, // Обновляем userData
    }));

    setBalanceStorage(prevState => ({// Обновляем balanceStorage
      ...prevState,
      trx_balance: result?.data?.trx_balance? result?.data?.trx_balance : 0,
      ww3_balance: result?.data?.ww3_balance? result?.data?.ww3_balance : 0, 
    }));

    console.log(result)
  } catch (error) {
    console.log(error)
  }
}
// Для отображения общей суммы на кошельке считаем сумму в портфолио
const [show_portfolio_TRX, set_show_portfolio_TRX] = useState(false)
const [show_portfolio_USDT, set_show_portfolio_USDT] = useState(false)
useEffect(() => {
   const TRX_summ = sunToTrx(balanceStorage.trx_balance) +
       (gweiToEth(balanceStorage.ww3_balance) * balanceStorage.ww3InUSDTCourse / 10**6) / balanceStorage.trxInUSDTCourse
   const USDT_summ = sunToTrx(balanceStorage.trx_balance) * balanceStorage.trxInUSDTCourse +
       (gweiToEth(balanceStorage.ww3_balance) * balanceStorage.ww3InUSDTCourse / 10**6)
   console.log(sunToTrx(balanceStorage.trx_balance), (gweiToEth(balanceStorage.ww3_balance) * balanceStorage.ww3InUSDTCourse / 10**6))
       set_show_portfolio_TRX(TRX_summ)
       set_show_portfolio_USDT(USDT_summ)
       console.log(balanceStorage)
 }, [balanceStorage]); 

useEffect(() => {
  getWalletInfo();
  console.log('getWalletInfo');
  const intervalId = setInterval(() => { // сохраняем id интервала, чтобы его можно было очистить
   //getWalletInfo();
  }, 50000);

  return () => {
    clearInterval(intervalId); // очистка при удалении компонента
  };
}, []);

const copy_text = async (text) => {
  await  navigator.clipboard.writeText(text).then(() => {console.log('copy_text!');}).catch(err => { console.log('Something went wrong', err);});
 }
const copy_wallet = (wallet) => {
   copy_text(wallet);
   Notification("Wallet address copied!", "success")
 }  
function manualCopyFallback(text) {
   let textArea = document.createElement('textarea');
   textArea.value = text;
   document.body.appendChild(textArea);
   textArea.focus();
   textArea.select();
   try {
     let successful = document.execCommand('copy');
     let msg = successful ? 'successful' : 'unsuccessful';
     console.log('Fallback: Copying text command was ' + msg);
   } catch (err) {
     console.error('Fallback: Oops, unable to copy', err);
   }
   document.body.removeChild(textArea);
 }

 const [seed, set_seed] = useState('false')
 const show_exportSeed = async () => {
   try {
    const result = await fetchSeed();    
    set_seed(result.data)
    set_show_ModalExportSeed(!show_ModalExportSeed)
   } catch (error) {
     console.log(error)
   }
 }

const exportSeed =  () => {
  try {
    manualCopyFallback(seed)
    set_show_ModalExportSeed(false)
    Notification('Seed has been copied to clipboard.', "success")
  } catch (error) {
    console.log(error)
  }
}

// Обслуживаем навигацию
const navigate = useNavigate();  
const goToWithdrawPage = (withdrawType) => {
  setUserStorage(prevState => ({
    ...prevState,
    withdrawType: withdrawType, // Обновляем withdrawType
  }));
  navigate(ROUTES.WITHDRAW);  // Перенаправляем на другую страницу
};

return (
<>
{show_ModalPoints && <ModalPoints set_show_ModalPoints={set_show_ModalPoints}/>}
<main className="main">
   <section className="wallet">
      <div className="container">
         <div className="wallet__network">
            <div className="wallet__network-value wallet__network-value_tron">TRON NETWORK</div>
         </div>
         <div className="wallet__info">
            <div className="wallet__info-code" onClick={event=>copy_wallet(userStorage.userData.account)}>
               <span className="wallet__info-code-text">{
               walletShortener(userStorage.userData.account)              
               }</span>
               <button className="wallet__info-code-copy" 
                style={{backgroundRepeat: 'no-repeat'}}
               onClick={event=>copy_wallet(userStorage.userData.account)}></button>
            </div>
            <div className="wallet__info-crypto">{numberShortener(show_portfolio_TRX, 4)} TRX</div>
            <div className="wallet__info-currency">${numberShortener(show_portfolio_USDT, 4)}</div>
            <div className={["wallet__info-more", show_ModalExportSeed? "active" : "" ].join(' ')} 
            onClick={event=>show_exportSeed()} >
               <button className="wallet__info-more-button"></button>
               {show_ModalExportSeed && <ModalExportSeed exportSeed={exportSeed}/>}
            </div>
         </div>
         <div className="wallet__tokens">
            <div className="wallet__tokens-card">
               <figure className="wallet__tokens-card-icon">
                  <img className="wallet__tokens-card-icon-image" src="./assets/trx.svg" alt="trx" />
               </figure>
               <div className="wallet__tokens-card-info">
                  <div className="wallet__tokens-card-info-title">TRX</div>
                  <div className="wallet__tokens-card-info-value">{sunToTrx(balanceStorage.trx_balance)} TRX</div>
               </div>
               <div className="wallet__tokens-card-status"  onClick={event=>goToWithdrawPage('trx')}></div>
            </div>
            <div className="wallet__tokens-card">
               <figure className="wallet__tokens-card-icon">
                  <img className="wallet__tokens-card-icon-image" src="./assets/ww3.png" alt="ww3" />
               </figure>
               <div className="wallet__tokens-card-info">
                  <div className="wallet__tokens-card-info-title">WW3 TOKEN</div>
                  <div className="wallet__tokens-card-info-value">{gweiToEth(balanceStorage.ww3_balance)} WW3</div>
               </div>
               <div className="wallet__tokens-card-status"  onClick={event=>goToWithdrawPage('ww3')}></div>
            </div>
            <div className="wallet__tokens-card wallet__tokens-card_points">
               <figure className="wallet__tokens-card-icon">
                  <img
                     className="wallet__tokens-card-icon-image"
                     src="./assets/points-icon.svg"
                     alt="points"
                     />
               </figure>
               <div className="wallet__tokens-card-info">
                  <div className="wallet__tokens-card-info-title">POINTS</div>
               </div>
               <div className="wallet__tokens-card-points">
                  <span className="wallet__tokens-card-points-value">{userStorage.userData.points}</span>
                  <button className="wallet__tokens-card-points-info" onClick={event=>set_show_ModalPoints(true)}>?</button>
               </div>
            </div>
         </div>
         <WalletSwapWidget />
         <div className="wallet__follow">
            <figure className="wallet__follow-figure">
               <img className="wallet__follow-figure-image" src="./assets/follow-x.jpg" alt="follow" />
            </figure>
            <a className="wallet__follow-link" >follow our x</a>
         </div>
      </div>
   </section>
</main>
</>
    );
};

export default WalletPage;