import React from 'react';

const ModalPoints = ({set_show_ModalPoints}) => {
    return (
<div className="modal modal_points active" onClick={event=>set_show_ModalPoints(false)}>
   <div className="modal__body" onClick={event =>event.stopPropagation()}>
      <h3 className="modal__title">What is Points?!</h3>
      <p className="modal__text">
         Points are our way of saying thanks for being one of the first WW3 ambassadors in
         history—we value our people! In the future, points will be redeemable for $WW3 tokens,
         along with other exclusive benefits. Stay tuned for more details.
      </p>
      <div className="modal__buttons">
         <button className="button" onClick={event=>set_show_ModalPoints(false)}>understand</button>
      </div>
   </div>
</div>
    );
};

export default ModalPoints;