import React from 'react';

const ModalExportSeed = ({exportSeed}) => {
    return (
<div className="wallet__info-more-modal " onClick={event=>exportSeed()}>
   <div className="wallet__info-more-modal-seed">
      <span className="wallet__info-more-modal-seed-phrase">Export seed phrase</span>
      <button className="wallet__info-more-modal-seed-copy" style={{backgroundRepeat: 'no-repeat'}}
      //onClick={event=>exportSeed()}
      ></button>
   </div>
   <div className="wallet__info-more-modal-warning">
      Warning: Don't do it if you don't know what it is
   </div>
</div>
    );
};

export default ModalExportSeed;