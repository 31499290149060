import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import gameImage  from "./assets/game-image.webp"
import Game_1_Card_Modal from './Game_1_Card_Modal';
import { ROUTES } from '../../../../utils/routes';
import GameTimer from '../GameTimer';

const Game_1_Card = ({initialMillis}) => {
   console.log(initialMillis);
// переменные для открытия попапов
const [show_PointsTotalModal, set_show_PointsTotalModal] = useState(false)

useEffect(()=>{

},[initialMillis])

const toggleModal = () =>{
   set_show_PointsTotalModal(!show_PointsTotalModal)
   document.body.classList.toggle('hidden');
}

const [playEnabled, set_playEnabled] = useState(false)

const navigate = useNavigate();  
const goGamePage = () => {

  navigate(ROUTES.GAME_GAME_1);  // Перенаправляем на другую страницу
};

return (
<>
{show_PointsTotalModal && <Game_1_Card_Modal set_show_PointsTotalModal={toggleModal}/>}
<div className="games__item">
   <figure className="games__item-figure">
      <img className="games__item-figure-image" src={gameImage} alt="game" />
   </figure>
   <div className="games__item-content">
      <h3 className="games__item-title">UFO TOWER GAME</h3>
      <div className="games__item-info">
         <div className="games__item-info-time">
            <GameTimer initialMillis={initialMillis} set_playEnabled={set_playEnabled} />           
         </div>
         <div className="games__item-info-attempts">timeout launch   </div>
      </div>
      <div className="games__item-description">
         Crypto ipsum bitcoin ethereum dogecoin litecoin. Aave siacoin arweave harmony
         dogecoin kusama hedera kava.
      </div>
      <div className="games__item-play">
         <button 
         className="games__item-play-button button"
         onClick={event=>goGamePage()}
         disabled={!playEnabled}>play</button>
      </div>
   </div>
   <div className="games__item-tag">
      <span className="games__item-tag-text">1km - 1 point</span>
      <button className="games__item-tag-button"
      onClick={event=>toggleModal(true)}
      >?</button>
   </div>
</div>
</>
    );
};

export default Game_1_Card;