import React from 'react';

const YourRefferalsModal = ({set_show_YourRefferalsModal}) => {
    return (
<div className="modal modal_referrals active"  onClick={event=>
   set_show_YourRefferalsModal(false)}>
   <div className="modal__body" onClick={event =>
      event.stopPropagation()}>
      <h3 className="modal__title">
         What kind of referrals are there and how much do they get paid for?
      </h3>
      <p className="modal__text">
         Points are our way of saying thanks for being one of the first WW3 ambassadors in
         history—we value our people! In the future, points will be redeemable for $WW3 tokens,
         along with other exclusive benefits. Stay tuned for more details.
      </p>
      <ul className="modal__list">
         <li className="modal__list-item modal__list-item_flex">
            <div className="modal__list-item-title">Referrals type one:</div>
            <div className="modal__list-item-text">
               one referrals - 10 Points (it is important that this referral makes conditions such as
               - signed up, something else, etc.)
            </div>
         </li>
         <li className="modal__list-item modal__list-item_flex">
            <div className="modal__list-item-title">Referrals type two:</div>
            <div className="modal__list-item-text">
               two referrals - 5 Points (it is important that this referral makes conditions such as
               - signed up, something else, etc.)
            </div>
         </li>
      </ul>
      <div className="modal__buttons">
         <button className="button" onClick={event=>set_show_YourRefferalsModal(false)}>understand</button>
      </div>
   </div>
</div>
    );
};

export default YourRefferalsModal;