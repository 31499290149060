export const shortener = (string, start_len, end_len) => {
    string = String(string)
    const start_string = string.slice(0, start_len)

    const end_string = string.slice(string.length-end_len, string.length)

    if (string.length > start_len + end_len + 1){
        if (end_len > 0){
            return   start_string + '...' + end_string
        } else {
            return   start_string + '...'
        }
    } else {
        return string
    }
}
export const  formatTimeMsToClock = (seconds) => {
    // Переводим миллисекунды во временные единицы
    //const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Вычисляем остаток времени после удаления дней, часов, минут
    const minutesLeft = minutes % 60;
    const hoursLeft = hours % 24;

    let timeString = "";

    // Строим строку в зависимости от того, сколько времени прошло
    timeString += days + ":";
    timeString += hoursLeft + ":";   
    timeString += minutesLeft + "";

    return timeString.trim();
}
export const  formatTimeMsToClock_2 = (milliseconds) => {
    // Переводим миллисекунды во временные единицы
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Вычисляем остаток времени после удаления дней, часов, минут
    const minutesLeft = minutes % 60;
    const hoursLeft = hours % 24;

    let timeString = "";

    // Строим строку в зависимости от того, сколько времени прошло
    if (days > 0) {
        timeString += days + ":";
    }
    if (days > 0 || hoursLeft > 0) {
        timeString += hoursLeft + ":";
    }
    timeString += minutesLeft + "";

    return timeString.trim();
}
export const USDformat = (text_value)=>{
    //console.log(text_value)
    /** Форматирование цифр в суммах - пробел каждые три знака и 2 знака после запятой */
    const m_f_options = { useGrouping: true , minimumFractionDigits: 2, maximumFractionDigits: 2 };  
    return Number(text_value).toLocaleString('ru-RU', m_f_options)
}  
export const PointsFormat = (text_value)=>{
    //console.log(text_value)
    /** Форматирование цифр в суммах - пробел каждые три знака и 0 знака после запятой */
    const m_f_options = { useGrouping: true , minimumFractionDigits: 0, maximumFractionDigits: 0 };  
    return Number(text_value).toLocaleString('ru-RU', m_f_options)
}
export const gweiToEth = (gwei) => {
    const eth = gwei / 1e18; // 1 ETH = 1e19 Gwei
    return parseFloat(eth.toFixed(3));
}
export const sunToTrx = (sun) => {
    const trx = sun / 1e6; // 1 TRX = 1e6 sun
    return parseFloat(trx.toFixed(3));
}
export const  formatTimestamp = (timestamp) =>  {
    //console.log(timestamp)
    const date = new Date(timestamp * 1000);
    //console.log(date)
    // Форматирование компонентов даты/времени, чтобы они всегда были в виде двух цифр
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() возвращает месяцы от 0 до 11
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Сборка итоговой строки даты и времени
    return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}
export const numberShortener = (number, decimals) => {    
    // Замена запятой на точку для корректного преобразования строки в число
    const numAsString = String(number).toString().replace(',', '.'); 
    // Преобразование в число и округление до нужного количества десятичных знаков
    return parseFloat(Number(numAsString).toFixed(decimals));
}
export const walletShortener = (wallet) => {   
    let result = ''
    try {
        result =  `${wallet.slice(0, 5)}...${wallet.slice(22,34)}`;
    } catch (error) {
        
    } 
    return result
}
export const isValidTronAddress = (address)=>  {
    // Адрес должен начинаться с 'T' и быть длиной 34 символа
    if (typeof address !== 'string' || address.length !== 34 || address[0] !== 'T') {
        return false;
    }
    // Символы должны быть допустимыми с точки зрения Base58 (без 0, O, I, l)
    const base58Chars = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    for (let i = 0; i < address.length; i++) {
        if (base58Chars.indexOf(address[i]) === -1) {
            return false;
        }
    }    
    // Здесь вы могли бы добавить дополнительные проверки, такие как проверка контрольной суммы,
    // если эта информация была бы доступна без дополнительных библиотек.
    return true;
}
export const TokenToGwei = (amountTokens, decimals) => {
    let newAmountToken = (amountTokens *  10 ** decimals).toFixed(0);
    newAmountToken = Number(newAmountToken).toLocaleString('fullwide', {useGrouping:false})    
    return newAmountToken;
}
export const formatBigSummToShortWrite = (num)  =>  {
    const m_f_options = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const m_f_options_2 = { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 };    
    if (num  == 0) {
      return 0;
    } else if (num < 100_000) {
      return (num).toLocaleString('ru-RU', m_f_options_2);
    } else if (num < 1_000_000) {
      return (num / 1_000).toLocaleString('ru-RU', m_f_options) + 'k';
    } else {
      return (num / 1_000_000).toLocaleString('ru-RU', m_f_options) + 'М';
    }
}
export const formatPercent = (num)  =>  {
    const m_f_options = { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 2 };  
    return (Number(num)).toLocaleString('ru-RU', m_f_options);
}