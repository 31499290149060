import {$authHost} from "./index"

export const fetchEntry = async () => {
    try {
        const {data} = await $authHost.get('api/social_platform/platform', { params: {}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}
export const fetchUsers = async (_data) => {
    try {
        const {data} = await $authHost.get('api/social_platform/platform_users', { params: {data: _data}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}
export const followUser = async ( _data) => {
    try {
        const {data} = await $authHost.post('api/social_platform/follow_user', { params: {data: _data}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}
export const executeQuest = async ( _data) => {
    try {
        const {data} = await $authHost.post('api/social_platform/execute_quest', { params: {data: _data}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}