import React from 'react';
import { Link, useNavigate } from "react-router-dom";

import { ROUTES } from '../../../utils/routes';

const SubscribePage = () => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate(ROUTES.WALLET);
  };
    return (
        <main className="main">
        <section className="subscribe">
          <div className="container">
            <h2 className="subscribe__title">
              Stay informed and ahead of the other humans by following our main <span>X page.</span>
            </h2>
  
            <p className="subscribe__description">Don’t miss out—click the button and join us!</p>
  
            <figure className="subscribe__figure">
              <img className="subscribe__figure-image" src="./assets/subscribe.jpg" alt="subscribe" />
            </figure>
  
            <div className="subscribe__controls">
              <button className="button"
              onClick={handleClick}
              >Continue</button>
            </div>
          </div>
        </section>
      </main>
    );
};

export default SubscribePage;