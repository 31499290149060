import {$authHost} from "./index"

export const fetchEntry = async () => {
    try {
        const {data} = await $authHost.get('api/social_connect/check', { params: {}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}











