import React, { useState, useEffect, useRef } from 'react';

const GameTimer = ({ initialMillis, set_playEnabled }) => {
    const [timeLeft, setTimeLeft] = useState(initialMillis);

    const intervalRef = useRef(null);

    // функция преобразования миллисекунд в ЧЧ:ММ:СС
    const formatTime = (time) => {
        const seconds = Math.floor(time / 1000) % 60;
        const minutes = Math.floor(time / (1000 * 60)) % 60;
        const hours = Math.floor(time / (1000 * 3600));

        // добавляем ведущие нули
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedHours = hours < 10 ? `0${hours}` : hours;

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {
        // Остановка таймера, когда время истекло
        if (timeLeft <= 0) {
            set_playEnabled(true)
            clearInterval(intervalRef.current);
            setTimeLeft(0)
            return;
        }

        // Установка интервала
        intervalRef.current = setInterval(() => {
            setTimeLeft((prevTimeLeft) => prevTimeLeft - 1000);
        }, 1000);

        // Очистка интервала
        return () => clearInterval(intervalRef.current);
    }, [timeLeft, initialMillis]);

    return (
       <>{formatTime(timeLeft)}</>
    );
};

export default GameTimer;
