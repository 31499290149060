import React from 'react';

const Modal_SocialUser = ({set_show_Modal}) => {
    return (
        <div class="modal modal_social-user  active" onClick={event=>set_show_Modal(false)}>
        <div class="modal__body" onClick={event => event.stopPropagation()}>
          <h3 class="modal__title">NFT avatar soon</h3>
  
          <p class="modal__text">
            After the launch of the nft collection in vv3 you will be able to set nft as your avatar
            and will receive additional daily points for it
          </p>
  
          <div class="modal__buttons">
            <button class="button" onClick={event=>set_show_Modal(false)}>understand</button>
          </div>
        </div>
      </div>
    );
};

export default Modal_SocialUser;