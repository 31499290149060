import React, { useState, useContext, useEffect } from "react";
import { Buffer } from 'buffer';
import SwapSelect from './SwapSelect';
import { TokenToGwei, gweiToEth, sunToTrx } from '../../../utils/utils';
import { sendSwap } from '../../../http/api_wallets';
import { ABI_TRC20, ABI_ROUTER_V2 } from './abi'
import { UserContext, UserStorageContext, BalanceStorageContext, NotificationContext } from '../../../contex';
import ModalCompleteSwap from "./ModalCompleteSwap";

window.Buffer = Buffer;
const TronWeb = window.TronWeb // TronWeb подключается как js в index.html

const WalletSwapWidget = () => {
    const {userStorage, setUserStorage} = useContext(UserStorageContext) // Хранилище данных
    const {balanceStorage, setBalanceStorage}  = useContext(BalanceStorageContext) // Хранилище данных
    const { Notification }  = useContext(NotificationContext) // Всплывающие сообщения
    const [rotate, setRotate] = useState(false);// работаем с анимацей кнопки поворота 
    
    const [max_trx_balance, set_max_trx_balance] = useState(userStorage.userData.trx_balance)
    const [max_ww3_balance, set_max_ww3_balance] = useState(userStorage.userData.ww3_balance)
    const [sourceAmount, setSourceAmount] = useState(0);
    const [targetAmount, setTargetAmount] = useState(0);
    const [sourceToken, setSourceToken] = useState('trx');
    const [targetToken, setTargetToken] = useState('ww3');
    const [priceImpact, setPriceImpact] = useState(null);
    const [speed, setSpeed] = useState('fast');
    const [sourceForSwap , set_sourceForSwap] = useState('trx') //отображаемый максимально доступный баланс в этом токене для свапа
    const DECIMALS ={ trx: 6, ww3: 18, }    
    const tronWeb = new TronWeb({
        fullHost: process.env.REACT_APP_TRON_FULL_HOST,
        privateKey: '54F7334A1C5D1DA669119452D56F49141C95F5EF692FB83851B6DCC32A4C7D5A' // моковый ключ иначе эта версия библиотеки глючит на фронте
    });

    const wtrx_TRC20_Address = process.env.REACT_APP_ADDRESS_WTRX 
    const ww3_TRC20_Address  = process.env.REACT_APP_ADDRESS_WW3
    const usdt_TRC20_Address  = process.env.REACT_APP_ADDRESS_USDT
    const routerAddress      = process.env.REACT_APP_ADDRESS_SUNSWAP_ROUTER_V2; // роутер сансвапа

    const sourceTokenAddress = wtrx_TRC20_Address; // WTRX
    const targetTokenAddress = ww3_TRC20_Address ; // WW3

  const requests = async () => {//Функция отвечающая за переодические запросы к блокчейну
    try {   console.log(userStorage)
        // Получаем баланс TRX
        const trx_balance_in_sun = await tronWeb.trx.getBalance(userStorage.userData.account);      
        console.log('trx_balance_in_sun', String(trx_balance_in_sun)) 
        // Получаем баланс WW3
        const WW3Contract = await tronWeb.contract(ABI_TRC20, ww3_TRC20_Address); 
        // Вызов метода balanceOf контракта токена
        const ww3_balance_in_gwei = await WW3Contract.methods.balanceOf(userStorage.userData.account).call();
        console.log('ww3_balance_in_gwei', String(ww3_balance_in_gwei)) 
        // Получаем курс TRX
        const routerContract = await tronWeb.contract().at(routerAddress);

        console.log(wtrx_TRC20_Address, usdt_TRC20_Address) 
       
        const TRXUSDT_amountsOut = await routerContract.methods.getAmountsOut(tronWeb.toSun('1'),
        [wtrx_TRC20_Address, usdt_TRC20_Address]).call();
        const TRXUSDT_resultAmounts = String(TRXUSDT_amountsOut[0])
        const TRXUSDT_resultAmountsArr = TRXUSDT_resultAmounts.split(',')

        console.log('TRXUSDT_resultAmountsArr', TRXUSDT_resultAmountsArr) 
        let trxInUSDTCourse = (TRXUSDT_resultAmountsArr[1] /( 10 ** 6))//.toFixed(6);
        //trxInUSDTCourse = Number(trxInUSDTCourse).toLocaleString('fullwide', {useGrouping:false})   
        console.log('trxInUSDTCourse', trxInUSDTCourse) 

        // Получаем курс WW3 к трону а затем пересчитываем по курсу
        // узнаем сколько получим за 1м токенов
        const WW3USDT_amountsOut = await routerContract.methods.getAmountsOut(TokenToGwei(1000000, 18),
        [ww3_TRC20_Address, wtrx_TRC20_Address]).call();
        const WW3USDT_resultAmounts = String(WW3USDT_amountsOut[0])
        const WW3USDT_resultAmountsArr = WW3USDT_resultAmounts.split(',')

        //console.log('WW3USDT_resultAmountsArr', WW3USDT_resultAmountsArr) 
        // ранее мы узнали сколько трона в SUNах получим за 1000_000 токенов ww3
        // конвертируем в целые единицы TRX
        let ww3InUSDTCourse = (WW3USDT_resultAmountsArr[1] /( 10 ** 6));
        //console.log('ww3InUSDTCourse', ww3InUSDTCourse)
        // теперь умножаем количество TRX на курс к USDT и получаем сумму в баксах за 1м
         ww3InUSDTCourse = Number( ww3InUSDTCourse) * Number(trxInUSDTCourse) 
        //ww3InUSDTCourse = Number(ww3InUSDTCourse).toLocaleString('fullwide', {useGrouping:false})   
        
        ww3InUSDTCourse = parseFloat(ww3InUSDTCourse.toFixed(6))
        console.log('ww3InUSDTCourse', ww3InUSDTCourse)
        setBalanceStorage(prevState => ({
            ...prevState,
            trxInUSDTCourse: trxInUSDTCourse,
            ww3InUSDTCourse: ww3InUSDTCourse, // не забываем что это курс за 1м
            trx_balance: trx_balance_in_sun,
            ww3_balance: String(ww3_balance_in_gwei), // Обновляем userData
          }));
        // Получаем курс WW3  
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{ // Запускаем переодическое получение информации из блокчейна
    requests();  
    const interval = setInterval(async ()=>{
      console.log("requests()")
      try {
          requests();    
      } catch (error) {
        console.log(error)
      }
    }, process.env.REACT_APP_DEX_REQUEST_INTERVAL)
    return() => {
        clearInterval(interval);
    };
    },[userStorage.userData.account])

    // Функция для получения курса обмена токенов
    const fetchExchangeRate = async () => {
        const routerContract = await tronWeb.contract().at(routerAddress);
        try {
            if (sourceToken =='trx') {
                const amountsOut = await routerContract.methods.getAmountsOut(tronWeb.toSun(sourceAmount),[sourceTokenAddress, targetTokenAddress]).call();
                const resultAmounts = String(amountsOut[0])
                const resultAmountsArr = resultAmounts.split(',')
               let newAmountToken = (resultAmountsArr[1] /( 10 ** DECIMALS['ww3'])).toFixed(0);
               newAmountToken = Number(newAmountToken).toLocaleString('fullwide', {useGrouping:false})               
               setTargetAmount(newAmountToken);
            } else  if (sourceToken =='ww3') {
                const amountsOut = await routerContract.methods.getAmountsOut(TokenToGwei(sourceAmount, DECIMALS['ww3']), [targetTokenAddress, sourceTokenAddress]).call();
                const resultAmounts = String(amountsOut[0])
                const resultAmountsArr = resultAmounts.split(',')
               let newAmountToken = (resultAmountsArr[1] /( 10 ** DECIMALS['trx'])).toFixed(0);
               newAmountToken = Number(newAmountToken).toLocaleString('fullwide', {useGrouping:false})               
               setTargetAmount(newAmountToken);
            }
        } catch (error) {
            console.error('Error fetching exchange rate', error);
        }
    };
    useEffect(() => {
        fetchExchangeRate();// получаем данные с сансвап
        set_sourceForSwap(sourceToken);// сообщаем в верхний виджет направление свапа
    }, [sourceAmount, sourceToken, targetToken]);

    function removeLeadingZeros(str) {
        // Преобразуем строку в число и обратно в строку для удаления ведущих нулей.
        // Если строка пустая или результат NaN, возвращаем её как есть.
        return str.replace(/^0+(?!$)/, '');
    }

    const handleSourceAmountChange = (e) => {
        let inputValue = e.target.value;

        // Убираем ведущий ноль, если он есть, и значение больше одной цифры
        if (inputValue.length > 1 && inputValue[0] === '0') {
            inputValue = inputValue.slice(1);
        }

        // Проверка, что значение содержит только цифры
        if (/^\d*$/.test(inputValue)) {
            setSourceAmount(inputValue);
        }
    
    };
    const handleSourceTokenChange = (e) => {
        
        setSourceToken(e.target.value);
    };
    const handleTargetTokenChange = (e) => {
        setTargetToken(e.target.value);
    };
    const swapFields = () => {
        const tempAmount = sourceAmount;
        setSourceAmount(targetAmount);
        setTargetAmount(tempAmount);

        const tempToken = sourceToken;
        setSourceToken(targetToken);
        setTargetToken(tempToken);

        // работаем с анимацей кнопки поворота 
        setRotate(true);

        // Убираем класс анимации после завершения
        setTimeout(() => {
          setRotate(false);
        }, 500); // 500 ms это время вашей анимации
    };
    const setDirectSwap = (tokenName) => {
        if (tokenName == 'trx') {
            setSourceToken('trx');
            setTargetToken('ww3');
        } else if (tokenName == 'ww3') {
            setSourceToken('ww3');
            setTargetToken('trx');
        }
        const tempAmount = sourceAmount;
        setSourceAmount(targetAmount);
        setTargetAmount(tempAmount);  
    };
    const setReverseSwap = (tokenName) => {
        if (tokenName == 'trx') {
            setSourceToken('ww3');
            setTargetToken('trx');
        } else if (tokenName == 'ww3') {
            setSourceToken('trx');
            setTargetToken('ww3');
        }
        const tempAmount = sourceAmount;
        setSourceAmount(targetAmount);
        setTargetAmount(tempAmount);  
    };
    const swapTransations = async () => {       
        try {
            // Чтобы интерфейс отрабатывал корректно отображение общего баланса нужно сразу отнять TRX
            // так как блокчейн списание trx со счета отдает позже чем зачисление ww3 и баланс задваивается
            if (sourceToken == "trx" && false) {// это нужно только при покупке ww3
                // отрабатывает один раз а потом по таймеру опять из блокчейна получаем неверные значения
                setBalanceStorage(prevState => ({
                    ...prevState,              
                    trx_balance: Number(balanceStorage.trx_balance) - sourceAmount *  10 ** DECIMALS['trx']
                  }));
            }
            Notification("Initiating swap...", "success");
            const result = await sendSwap({sourceAmount, sourceToken, targetToken,speed})
            if (result.status == "OK") {
                console.log(result.message)
            } else if (result.status =="ERROR") {
                console.log(result.message)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleSpeedChange = (event) => {// обработчик формы выбора скорости транзакции
        setSpeed(event);
        console.log(event)
    };  
    const calculatePriceImpact = (sourceAmount, targetAmount) => {
          // Implement your logic to calculate price impact
          // For demonstration, let's assume it's a simple percentage calculation
          return ((sourceAmount - targetAmount) / sourceAmount) * 100;
    };
    const showSourceForSwapBalance = () =>{// отображаемый  максимально доступный баланс в этом токене для свапа
        if (sourceForSwap == "trx") {
            return `${sunToTrx(balanceStorage.trx_balance)} TRX`
        } else if (sourceForSwap == "ww3"){
            return `${gweiToEth(balanceStorage.ww3_balance)} WW3`
        } else {
            return `---`
        }
    }
    // Текст кнопки свапа
    const actionText = () => {        
        const balanceForFee = 3 * 10 ** 6 // количество которое нужно на балансе для оплаты коммисий
        if (sourceAmount == 0 ) { // если в поле ввода введен 0
            return "ENTER VALUE"
        } else {
            if (sourceForSwap == "trx") { 
                if (sourceAmount > Number(sunToTrx(balanceStorage.trx_balance))) {
                    return "NOT ENOUGH TRX"
                }
                if (sourceAmount * 10 ** DECIMALS['trx'] > Number(balanceStorage.trx_balance) - Number(balanceForFee)) {
                    return "NOT ENOUGH TRX FOR GAS"
                }
                return "SWAP"
            } else if (sourceForSwap == "ww3"){
                if (sourceAmount > Number(gweiToEth(balanceStorage.ww3_balance))) {
                    return "NOT ENOUGH WW3"
                }
                if (Number(balanceForFee) > Number(balanceStorage.trx_balance) ) {
                    return "NOT ENOUGH TRX FOR GAS"
                }
                return "SWAP"
            }  
        } 
    }
const [show_ModalCompleteSwap, set_show_ModalCompleteSwap] = useState(false);
// Функция обработки нажатия кнопки свапа
const handleSwapButtonClick = () => {
    const action = actionText(); // Вызываем actionText для получения статуса операции
    console.log("action", action);
    if (action == "ENTER VALUE"){
        console.log("Please enter a value to swap.");
        Notification("Please enter a value to swap.", "error");
    } else if (action == "NOT ENOUGH TRX"){
        console.log("You do not have enough TRX.");
        Notification("You do not have enough TRX.", "error");
    } else if (action == "NOT ENOUGH WW3"){
        console.log("You do not have enough WW3.");
        Notification("You do not have enough WW3.", "error");
    } else if (action == "NOT ENOUGH TRX FOR GAS"){
        console.log("You do not have enough TRX to cover the gas fees for the swap.");
        Notification("You do not have enough TRX to cover the gas fees for the swap.", "error");
    } else if (action == "SWAP"){
        // открываем окно подверждения свапа в котором человек апрувнет совершение транзакции       
        set_show_ModalCompleteSwap(true)
    } else {
        // Если появляется неизвестный ответ, отображаем ошибку
        Notification("Error during swap operation: " + action, "error");
        console.log("Error during swap operation: " + action);
    }
 };

    return (
<> 
{show_ModalCompleteSwap &&
<ModalCompleteSwap
    YouSend = { sourceAmount }
    YouGet = { targetAmount }
    TxFee = { 3 }
    SwapSpeed = { speed }
    targetToken = { targetToken }
    set_show_Modal = { set_show_ModalCompleteSwap }
    confirm_swap = {  swapTransations }
/>}
<div className="wallet__swap">
            <div className="wallet__swap-heading">
                <span className="wallet__swap-heading-title">Swap</span>
                <span className="wallet__swap-heading-limit">Max: {showSourceForSwapBalance()}</span>
            </div>
            <form className="wallet__swap-form" onClick={event=>event.preventDefault()}>
                <fieldset className="wallet__swap-form-fieldset">
                    <input
                        className="wallet__swap-form-input"
                        type="text"
                        id="source"
                        name="source"
                        value={sourceAmount}
                        onChange={event=>handleSourceAmountChange(event)}
                        inputMode="numeric"
                    />
                    <SwapSelect setDirectSwap = {setDirectSwap} selectedToken= {sourceToken} />
                    {false && <div className="custom-select" data-value={sourceToken}>
                        <select id="sourceToken" name="sourceToken" value={sourceToken} onChange={handleSourceTokenChange}>
                            <option value="trx">TRX</option>
                            <option value="ww3">WW3</option>
                        </select>
                    </div>}
                    <div  className={`arrow-right ${rotate ? "arrow-right-rotate" : ""}`}
                    onClick={swapFields} style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '50%',
                            height: '32px',
                            width: '32px',
                            borderRadius: '32px',
                            background: 'url(../assets/rotating-arrow-symbol-svgrepo-com.svg) center no-repeat',
                            backgroundColor: '#a5a5a5',
                            transform: 'translate3d(-50%, 50%, 0)'
                            }}></div>
                </fieldset>
                
                <fieldset className="wallet__swap-form-fieldset">
                    <input
                        className="wallet__swap-form-input"
                        type="number"
                        id="target"
                        name="target"
                        value={targetAmount}
                        readOnly
                    />
                    <SwapSelect setDirectSwap = {setReverseSwap} selectedToken = {targetToken} />
                    {false && <div className="custom-select" data-value={targetToken}>
                        <select id="targetToken" name="targetToken" value={targetToken} onChange={handleTargetTokenChange}>
                            <option value="ww3">WW3</option>
                            <option value="trx">TRX</option>
                        </select>
                    </div>}

                </fieldset>
               {false && <div>
                    Price Impact: {priceImpact ? `${priceImpact.toFixed(2)}%` : 'Calculating...'}
                </div>}
                <fieldset className="wallet__swap-form-fieldset wallet__swap-form-fieldset_speed wallet__swap-form-speed">
                    <div className="wallet__swap-form-speed-title">Speed:</div>
                    <div className="wallet__swap-form-speed-inputs">
                        <label className="wallet__swap-form-speed-label">
                            <span className="wallet__swap-form-speed-label-text"
                             onClick={event=> handleSpeedChange('default')}>Default</span>
                            <input type="radio"  name="speed" value="default" 
                             checked={speed === 'default'}
                             />
                        </label>
                        <label className="wallet__swap-form-speed-label">
                            <span className="wallet__swap-form-speed-label-text"
                            onClick={event=> handleSpeedChange('fast')}>Fast</span>
                            <input type="radio" name="speed" value="fast" 
                            checked={speed === 'fast'}/>
                        </label>
                        <label className="wallet__swap-form-speed-label">
                            <span className="wallet__swap-form-speed-label-text"  
                            onClick={event=> handleSpeedChange('instant')}>Instant</span>
                            <input type="radio" name="speed" value="instant"  
                            checked={speed === 'instant'} 
                           />
                        </label>
                    </div>  
                </fieldset>
                <fieldset className="wallet__swap-form-fieldset">
                    <button 
                    style={{opacity:actionText() == "SWAP"?  1 : 0.5}}
                    className="button" onClick={event=>handleSwapButtonClick()}>{actionText()}</button>
                </fieldset>
            </form>
        </div>
</>
    );
};

export default WalletSwapWidget;