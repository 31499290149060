import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { createWallet } from '../../../http/api_wallets';
import { ROUTES } from '../../../utils/routes';
import { InitDataContext  } from '../../../contex';

const StartPage = () => {
  const { initData }  = useContext(InitDataContext) // извлекаем инитдату для получения реферера

  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      const _referrer = initData?.start_param? initData?.start_param : null
      const result = await createWallet(_referrer);
      console.log(result)
      if (result.status == "OK") {
        navigate(ROUTES.SUBSCRIBE);
      }      
    } catch (error) {
      console.log(error)
    }
  };

return (
<main className="main">
<section className="first-step">
  <div className="container">
    <div className="first-step__logos">
      <figure className="first-step__logos-item">
        <img className="first-step__logos-item-image" src="./assets/ww3-logo.png" alt="ww3" />
      </figure>

      <figure className="first-step__logos-item">
        <img
          className="first-step__logos-item-image"
          src="./assets/tron-trx-logo-full.svg"
          alt="tron"
        />
      </figure>
    </div>

    <p className="first-step__text">
      If you’re reading this, congratulations—you’ve got what it takes to recognize a great
      opportunity. Now, you’re ready to join the fight. Welcome to the Reptilian revolution!
    </p>

    <figure className="first-step__figure">
      <img
        className="first-step__figure-image"
        src="./assets/first-step-image.png"
        alt="first-step"
      />
    </figure>
    <div className="first-step__controls">
      <button className="button" onClick={handleClick}>
        Create Wallet
      </button>
    </div>
  </div>
</section>
</main>
    );
};

export default StartPage;