import React, { useState } from 'react';
import QuestTimer from './QuestTimer';

const SocialQuestsBlock = ({data, set_openQuestPage, sendQuest}) => {
//const [quests, set_quests] = useState(data?.quests? data?.quests : [{}])
const quests = data?.quests? data?.quests : [{}]

const statusStyle = (status) =>{
   if (status == "active") {
      return "social__quests-item"
   } else if (status == "done") {
      return "social__quests-item social__quests-item_done"
   } else {
      return "social__quests-item social__quests-item_expired"
   }
}
const buttonText = (status) =>{
   if (status == "active") {
      return "Follow"
   } else if (status == "done") {
      return "Done"
   } else {
      return "Expired"
   }
}

    return (
<div className="social__quests">         
            <h2 className="social__quests-title">QUESTS</h2>
            <div className="social__quests-content">
               {quests.map(p=>
                  <div className={statusStyle(p.status)} key={p.soc_quest_id}>
                  <div className="social__quests-item-top">
                     <div className="social__quests-item-top-points">{p.points} point</div>
                     <div className="social__quests-item-top-type">follow</div>
                     <div className="social__quests-item-top-time">
                        {p.left && p.left > 0 ?
                        <QuestTimer initialMillis = {p.left * 1000}/>
                        : 
                        '00:00'
                        }      
                        </div>
                  </div>
                  <div className="social__quests-item-body">
                     <div className="social__quests-item-text">
                        JUST FOLLOW ITS CHANNEL - <a href={p.twt_link} target='_blank'>{p.twt_link}</a>
                     </div>
                     <div className="social__quests-item-controls">
                        <button className="button" onClick={event=>sendQuest(p.soc_quest_id)} >
                           <a style={{color:'white'}} 
                           //href={p.twt_link} 
                           target='_blank'>{buttonText(p.status)}</a>
                        </button>
                     </div>
                  </div>
               </div>
               )}
            </div>
            <a className="social__quests-more" onClick={event=>set_openQuestPage(true)}></a>
         </div>
    );
};

export default SocialQuestsBlock;