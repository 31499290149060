import React, { useState, useEffect } from 'react';
import Game_1_Card from './game_1/Game_1_Card';
import Game_2_Card from './game_2/Game_2_Card';
import { fetchEntrys, saveEntry } from '../../../http/api_games';

const GameMenuPage = () => {
   const [ gamesData, set_gamesData ] = useState({})
   const [ isLoading, set_isLoading]= useState(false)
   const getWalletInfo = async() => {//  при загрузке страницы получаем данные о последних играх
      try {
        const result = await fetchEntrys(); 
        console.log(result.data);
        set_gamesData(result.data);   
        set_isLoading(true) 
      } catch (error) {
        console.log(error)
      }
  }
  useEffect(() => {
      getWalletInfo();
      console.log('getWalletInfo');
  }, []);

return (
<main className="main">
   <section className="games">
      <div className="container">
         <div className="games__content">
            {isLoading && <Game_1_Card initialMillis = {gamesData.game_1_timeout? gamesData.game_1_timeout :0 }/>  }
               
            <Game_2_Card/>  
         </div>
      </div>
   </section>
</main>
    );
};

export default GameMenuPage;