import React from 'react';

const PointsTotalModal = ({set_show_PointsTotalModal}) => {
    return (
        <div className="modal modal_points active"  onClick={event=>set_show_PointsTotalModal(false)}>
        <div className="modal__body" onClick={event => event.stopPropagation()}>
          <h3 className="modal__title">What is Points?!</h3>
  
          <p className="modal__text">
            Points are our way of saying thanks for being one of the first WW3 ambassadors in
            history—we value our people! In the future, points will be redeemable for $WW3 tokens,
            along with other exclusive benefits. Stay tuned for more details.
          </p>
  
          <p className="modal__text">How many points do you get for what?</p>
  
          <ul className="modal__list">
            <li className="modal__list-item modal__list-item_flex">
              <div className="modal__list-item-title">Wallet:</div>
              <div className="modal__list-item-text">1 transaction over 100trx - 50 points</div>
            </li>
            <li className="modal__list-item modal__list-item_flex">
              <div className="modal__list-item-title">SP:</div>
              <div className="modal__list-item-text">
                Subscription - 10 points Change description - 50 points Something else - 100 points
              </div>
            </li>
            <li className="modal__list-item modal__list-item_flex">
              <div className="modal__list-item-title">Game:</div>
              <div className="modal__list-item-text">Winning a daily something - 500 points</div>
            </li>
            <li className="modal__list-item modal__list-item_flex">
              <div className="modal__list-item-title">Refferal:</div>
              <div className="modal__list-item-text">For an invited friend - 100 points</div>
            </li>
          </ul>
  
          <div className="modal__buttons">
            <button className="button"  onClick={event=>set_show_PointsTotalModal(false)}>understand</button>
          </div>
        </div>
      </div>
    );
};

export default PointsTotalModal;