import { useEffect, useState, useContext }  from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../utils/routes";
import { UserContext } from '../contex';
import Styles from './Tpl_main.module.css'; 

const Tpl_main = (props) => {

   const {page, menuOn = true} = props
   //Обслуживаем боковое меню в рамках ролевой модели  
   const usermenu = [
    {link:ROUTES.PROFILE,         className: 'main-nav__link main-nav__link_profile', name: 'Profile' },
    {link:ROUTES.WALLET,          className: 'main-nav__link main-nav__link_wallet',  name: 'Wallet' },
    {link:ROUTES.SOCIALCONNECT,   className: 'main-nav__link main-nav__link_social',  name: 'Social platform' },
    {link:ROUTES.GAME,            className: 'main-nav__link main-nav__link_game', name: 'Game' },
  ]  

   //Обслуживаем title страницы 
   const location = useLocation()
   const currentItem = location.pathname.replaceAll('/','');
   useEffect(() => {
     document.title =  'WW3 / '+ currentItem;
    }, [currentItem]);

    const getMenuItemStyle = (link) =>{
        if (location.pathname == link) {
          return "active"
        } else if ( location.pathname == ROUTES.SOCIALPLATFORM && link == ROUTES.SOCIALCONNECT )  {
          return "active"
        }
         return ""
    }

   return(
      <>
    {page}
    {menuOn && <nav className="main-nav">
        <div className="container">
          {usermenu.map(p =>    
          <Link key={p.name} to={p.link} className={[p.className,   getMenuItemStyle(p.link)
         //  location.pathname === p.link? "active" : "" 
         //p.name === 'Wallet'? "active" : ""
          ].join(' ')}>
            {p.name}
          </Link>)}
         {false && <Link to={''} className={['main-nav__link main-nav__link_social', ""].join(' ')}>
            Social platform
          </Link>}
         {false && <Link to={''} className={['main-nav__link main-nav__link_game', ""].join(' ')}>
              Game
          </Link>}
        </div>
    </nav>}
        </>
    )
}

export default Tpl_main;