import React, { useState, useContext, useEffect } from "react";
import { getSwapPoints } from "../../../http/api_wallets";

const ModalCompleteSwap = ({
    YouSend,
    YouGet,
    TxFee,
    SwapSpeed,
    targetToken,
    set_show_Modal,
    confirm_swap
}) => {
    const [points_wallet , set_points_wallet ] = useState(0)

    const confirm = () =>{
        set_show_Modal(false)
        confirm_swap()
    }
    const getInfo = async () => {
        try {
            const result = await getSwapPoints({YouSend, targetToken});    
            console.log(result.data)
            set_points_wallet(result.data)

           } catch (error) {
             console.log(error)
           }
    }
    useEffect(()=>{ // Запускаем переодическое получение информации из блокчейна
        getInfo()
    },[])
    return (
<div className="modal modal_complete-swap active"  onClick={event=>set_show_Modal(false)}>
   <div className="modal__body" onClick={event =>event.stopPropagation()}>
      <h3 className="modal__title">Complete Swap</h3>
      <p className="modal__description">
         Before swapping, make sure that all the data is entered correctly, be careful!
      </p>
      <ul className="modal__list">
         <li className="modal__list-item">
            <b>You send:</b>
            <span> { YouSend }</span>
            <span>{targetToken == 'ww3'? "TRX" : "WW3" }</span>
         </li>
         <li className="modal__list-item">
            <b>You get:</b>
            <span> { YouGet }</span>
            <span>{targetToken == 'ww3'? "WW3" : "TRX" }</span>
         </li>
         <li className="modal__list-item">
            <b>Transaction fee:</b>
            <span> { TxFee }</span>
            <span>TRX</span>
         </li>
         <li className="modal__list-item">
            <b>You will earn:</b>
            <span> {points_wallet}</span>
            <span> POINTS</span>
         </li>
         <li className="modal__list-item">
            <b>Swap speed:</b>
            <span> { SwapSpeed }</span>
         </li>
      </ul>
      <div className="modal__buttons">
         <button className="button button_confirm"onClick={event=>confirm()}>confirm</button>
         <button className="button button_light" onClick={event=>set_show_Modal(false)}>cancel</button>
      </div>
   </div>
</div>
    );
};

export default ModalCompleteSwap;