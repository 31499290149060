import React from 'react';

const Modal_SocialFollowing = ({set_show_Modal}) => {
    return (
<div class="modal modal_social-following active"  onClick={event=>set_show_Modal(false)}>
      <div class="modal__body" onClick={event => event.stopPropagation()}>
        <h3 class="modal__title">Following</h3>

        <p class="modal__text">
          Subscribe to as many vv3 community members as possible in social network X and get daily
          points.
        </p>

        <ul class="modal__list">
          <li class="modal__list-item modal__list-item_flex">
            <div class="modal__list-item-title">5-10%</div>
            <div class="modal__list-item-text">N points</div>
          </li>
          <li class="modal__list-item modal__list-item_flex">
            <div class="modal__list-item-title">10-30%</div>
            <div class="modal__list-item-text">N points</div>
          </li>
          <li class="modal__list-item modal__list-item_flex">
            <div class="modal__list-item-title">30-50%</div>
            <div class="modal__list-item-text">N points</div>
          </li>
          <li class="modal__list-item modal__list-item_flex">
            <div class="modal__list-item-title">50-80%</div>
            <div class="modal__list-item-text">N points</div>
          </li>
          <li class="modal__list-item modal__list-item_flex">
            <div class="modal__list-item-title">80-100%</div>
            <div class="modal__list-item-text">N points</div>
          </li>
        </ul>

        <div class="modal__buttons">
          <button class="button"  onClick={event=>set_show_Modal(false)}>understand</button>
        </div>
      </div>
    </div>
    );
};

export default Modal_SocialFollowing;