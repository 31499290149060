export function decodeInitData(telegramInitData) {
    const initData = new URLSearchParams(telegramInitData);
    // Удаляем hash из параметров
    initData.delete("hash");
      
    let result = {}
    const dataToCheck = [...initData.entries()]
      .map(([key, value]) => {
        if (key === "user") {                
          value = JSON.stringify(JSON.parse(decodeURIComponent(value))); // Декодируем и возвращаем в JSON для правильной сортировки
        } else {
          value = decodeURIComponent(value);
        }
        result[key] = value;
      })
      .sort();  
    return result;
}
  
