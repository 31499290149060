
import { Route, Routes, Navigate } from "react-router-dom";
import React, { useContext } from 'react';
import { UserContext } from '../contex';
import { ROUTES } from "../utils/routes";
import Tpl_login from "../templates/Tpl_login";
import NotAuth from "../pages/notAuth";
import StartPage from "../pages/login/start/StartPage";
import SubscribePage from "../pages/login/subscribe/SubscribePage";

import Tpl_main from "../templates/Tpl_main";

import ProfilePage from "../pages/main/profile/ProfilePage";

import WalletPage from "../pages/main/wallet/WalletPage";

import SocialPlatformPage from "../pages/main/socialPlatform/SocialPlatformPage";
import SocialConnectPage from "../pages/main/socialConnect/SocialConnectPage";
//import QuestsPage from "../pages/main/socialPlatform/QuestsPage";

import GameMenuPage from "../pages/main/game/GameMenuPage";
import Game_1_Page from "../pages/main/game/game_1/Game_1_Page";

import WithdrawPage from "../pages/main/withdraw/WithdrawPage";

const AppRouter = () =>{
const {user} = useContext(UserContext)
const publicRoutes = [ // Если юзер не из телеграма стучится на сайт
      {path: ROUTES.NOTAUTH, element: <Tpl_login page = {<NotAuth/>} />},
      {path: '*', element: <Navigate to={ROUTES.NOTAUTH} replace/>}
   ]
   
let authRoutes = [ // Если юзер авторизован через телеграмм
      {path: ROUTES.PROFILE,          element: <Tpl_main page = {<ProfilePage/>} />},
      {path: ROUTES.WALLET,           element: <Tpl_main page = {<WalletPage/>} menuOn = {true} />},
      {path: ROUTES.WITHDRAW,         element: <Tpl_main page = {<WithdrawPage/>} menuOn = {false} />},
      {path: ROUTES.SOCIALCONNECT,    element: <Tpl_main page = {<SocialConnectPage/>} />},
      {path: ROUTES.SOCIALPLATFORM,   element: <Tpl_main page = {<SocialPlatformPage/>} />},
      //{path: ROUTES.QUESTS,           element: <Tpl_main page = {<QuestsPage/>} />},
      {path: ROUTES.GAME,             element: <Tpl_main page = {<GameMenuPage/>} />},
      {path: ROUTES.GAME_GAME_1,      element: <Tpl_main page = {<Game_1_Page/>} />},
     // Если юзер не создавал кошелек is_created == 0 то добавляем маршруты для создания
      {path: '*',  element: <Navigate to={ user.is_created == 0? ROUTES.CREATE_WALLET : ROUTES.WALLET} replace/>}
   ]
   // Если юзер не создавал кошелек is_created == 0 то добавляем маршруты для создания
   if (user.is_created == 0) {
      authRoutes.push({path: ROUTES.CREATE_WALLET,  element: <Tpl_login page = {<StartPage/>}/> })
      authRoutes.push({path: ROUTES.SUBSCRIBE,      element: <Tpl_login page = {<SubscribePage/>} />})
   }
   console.log(authRoutes)

 return(
         <>
         <Routes>
            {
            user.isAuth?
               authRoutes.map((p)=> <Route key={authRoutes.indexOf(p)} path = {p.path} element = {p.element}/>)
            :
               publicRoutes.map((p)=><Route key={publicRoutes.indexOf(p)} path = {p.path} element = {p.element}/>)
            }
         </Routes>
         </>
   )
}

export default AppRouter;