import {$authHost} from "./index"

export const createWallet = async (referrer) => {
    try {
        const {data} = await $authHost.get('api/wallets/wallet_create', { params: {referrer: referrer}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const fetchEntry = async () => {
    try {
        const {data} = await $authHost.get('api/wallets/wallet', { params: {}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const fetchSeed = async () => {
    try {
        const {data} = await $authHost.get('api/wallets/wallet_seed', { params: {}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const sendTransfer = async ( _data) => {
    try {
        const {data} = await $authHost.post('api/wallets/wallet_transfer', { params: {data: _data}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}
export const sendSwap = async ( _data) => {
    try {
        const {data} = await $authHost.post('api/wallets/wallet_swap', { params: {data: _data}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getSwapPoints = async (_data) => {
    try {
        const {data} = await $authHost.get('api/wallets/get_swap_points', { params: {data: _data}});
        return data
    } catch (error) {
        console.log(error)
        return error
    }
}











