import React from 'react';

const Modal_SocialFaq = ({set_show_Modal}) => {
    return (
    <div class="modal modal_social-faq active" onClick={event=>set_show_Modal(false)}>
      <div class="modal__body"  onClick={event => event.stopPropagation()}>
        <h3 class="modal__title">How does it work?</h3>

        <p class="modal__text">
          We're giving you points, dude, because you're one of the first WW3 ambassadors in history,
          we value our people.
        </p>

        <p class="modal__text">The situation is as follows:</p>

        <ul class="modal__list modal__list_typed">
          <li class="modal__list-item">ONE POINT = 2 $WW3</li>
          <li class="modal__list-item">
            Points can be exchanged for a W3 token with a balance on a W3 wallet (This is this
            wallet if anything) of at least $100,000WW3
          </li>
        </ul>

        <div class="modal__buttons">
          <button class="button" onClick={event=>set_show_Modal(false)}>understand</button>
        </div>
      </div>
    </div>
    );
};

export default Modal_SocialFaq;