import React from 'react';
import { ROUTES } from '../../../../utils/routes';
import { Link, useNavigate } from "react-router-dom";

const GameResult = ({finalScore}) => {
    const navigate = useNavigate();
    
    const handleClick = async () => {
      try {
          navigate(ROUTES.GAME);  
      } catch (error) {
        console.log(error)
      }
    };

    return (
<div className="main">
            <div className="background paused"
              style={{
                position: 'absolute',
                width: '300%',
                height: '8%',
                top: 0,
                left: 0,
                backgroundImage: 'url("/assets/images/IMG_7137.PNG")', // Укажите абсолютный путь
                backgroundRepeat: 'repeat-x',
                backgroundSize: '600px',
                zIndex: 2
              }}
            ></div>
            <div className="game__area">
                <div className="game-over">
                    <h1>Your score: {finalScore} km</h1>
                    <button className="play_again "
                    onClick={event => handleClick()}
                    >GAME MENU</button>
                    <br/>
                </div>
            </div>
        </div>
    );
};

export default GameResult;