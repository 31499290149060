export const ROUTES = {
    NOTAUTH: '/notauth',
    MAIN: '/',
    CREATE_WALLET: '/CREATE_WALLET',
    SUBSCRIBE: '/SUBSCRIBE',
    // usermenu
    PROFILE: '/PROFILE',
    WALLET: '/WALLET',
    WITHDRAW: '/WITHDRAW',
    SOCIALCONNECT: '/SOCIALCONNECT',
    SOCIALPLATFORM: '/SOCIALPLATFORM',
    QUESTS: '/QUESTS',
    GAME: '/GAME',
    GAME_GAME_1: '/GAME/GAME_1',
}

 
