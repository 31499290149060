import React, { useState, useContext, useEffect } from "react";
import { walletShortener, formatBigSummToShortWrite, gweiToEth, formatPercent } from "../../../utils/utils";

const SocialUsers = ({dataUsers, sendFollow}) => {
   console.log(dataUsers)
    return (
<div className="social__users" >
   {dataUsers.map(p=>
   <div className="social__users-card" key={dataUsers.indexOf(p) }>
      <figure className="social__users-card-photo">{console.log(dataUsers)}
         <img
            className="social__users-card-photo-image"
            src={p.twt_photos}
            alt="photo"
            />
      </figure>
      <div className="social__users-card-info">
         <div className="social__users-card-info-name">{p.twt_username}</div>
         <div className="social__users-card-info-description">{walletShortener(p.account)}</div>
         <div className="social__users-card-info-token">
            <div className="social__users-card-info-token-cash">{
            formatBigSummToShortWrite(gweiToEth(p.ww3_balance))
            }</div>
            <div className="social__users-card-info-token-name">$WW3 TOKEN</div>
         </div>
      </div>
      <div className="social__users-card-details">
         <div className="social__users-card-details-item">
            <div className="social__users-card-details-item-value">{formatPercent(p.following_percent)}%</div>
            <div className="social__users-card-details-item-text">FOLLOWING</div>
         </div>
         <div className="social__users-card-details-item">
            <div className="social__users-card-details-item-value">{formatPercent(p.followers_percent)}%</div>
            <div className="social__users-card-details-item-text">FOLLOWED BY</div>
         </div>
         <div className="social__users-card-details-item">
            <div className="social__users-card-details-item-value">{formatPercent(p.points)}</div>
            <div className="social__users-card-details-item-text">POINT</div>
         </div>
      </div>
      <div className="social__users-card-follow"
      onClick={event=>sendFollow(p.soc_user_id)}
      >
         <button className="button">Follow</button>
      </div>
   </div>
   )}
   
   {false && <div className="social__users-back ">
      <a className="social__users-back-link" href="#socialFilter">Go back to the start</a>
   </div>}
</div>
    );
};

export default SocialUsers;