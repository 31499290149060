import React, { useState, useContext, useEffect } from "react";
import { walletShortener, USDformat, gweiToEth } from "../../../utils/utils";

const SocialNewcomersBlock = ({data}) => {
   const [ lastCreatedUsers, set_lastCreatedUsers ] = useState(data?.lastCreatedUsers? data?.lastCreatedUsers : [{}])
    return (
<div className="social__newcomers">
   <h2 className="social__newcomers-title">NEW REPTILOIDS:</h2>
   <div className="swiper social__newcomers-slider-top">
      <div className="swiper-wrapper">
         {lastCreatedUsers.map(p=>
         <div className="swiper-slide" key={p.twt_username + lastCreatedUsers.indexOf(p) }>
            <div className="social__newcomers-slide">
               <figure className="social__newcomers-slide-photo">
                  <img
                     className="social__newcomers-slide-photo-image"
                     src={p.twt_photos}
                     alt="slide"
                     />
               </figure>
               <div className="social__newcomers-slide-user">
                  <div className="social__newcomers-slide-user-name">{p.twt_username}</div>
                  <div className="social__newcomers-slide-user-token">{walletShortener(p.account)}</div>
                  <div className="social__newcomers-slide-user-time">{p.created_ago}</div>
               </div>
            </div> 
            <br/>
         </div>)}
      </div>
   </div>
</div>
    );
};

export default SocialNewcomersBlock;